import React, {Fragment} from "react";
import {BreadcrumbRoute} from "../models/breadcrumb_route";
import {Link, Match, MatchRenderProps} from "@reach/router";
import {useTranslation} from "react-i18next";


interface BreadcrumbsComponentProps {
    breadcrumbs: BreadcrumbRoute[];
}

export const BreadcrumbsComponent = <T, >({breadcrumbs}: BreadcrumbsComponentProps) => {

    const {t} = useTranslation();

    return (
        <>{
            breadcrumbs.map((breadcrumb, index) => {
                return (
                    <Match<T> key={index} path={breadcrumb.route}>
                        {props => (
                            <Fragment key={index}>
                                {
                                    // eslint-disable-next-line
                                    props.match ? (
                                            <Fragment key={`match_${index}`}>
                                                {breadcrumb.parts.map((part, index) => {
                                                    return <Fragment key={index}>{
                                                        <>
                                                            {part.url ?
                                                                <Link key={index}
                                                                      to={renderUrl<T>(part.url, props)}>{t(part.title)}</Link> :
                                                                <>{t(part.title)}</>
                                                            }
                                                            {(index < breadcrumb.parts.length - 1) ?
                                                                <> / </> :
                                                                <></>
                                                            }
                                                        </>
                                                    }
                                                    </Fragment>;
                                                })}
                                            </Fragment>
                                        ) :
                                        <></>
                                }
                            </Fragment>
                        )}
                    </Match>);
            })
        }</>
    );
};

function renderUrl<T>(urlTemplate: string, matchProps: MatchRenderProps<T>): string {
    const parameterValues = urlTemplate
        .split("/")
        .filter(urlTemplatePart => urlTemplatePart.startsWith(":"))
        .map(parameter => parameter.substr(1))
        .map(parameter => {
            return {parameter, value: matchProps.match[parameter]};
        });

    let url = urlTemplate;
    for (let i = 0; i < parameterValues.length; i++) {
        url = url.replace(`:${parameterValues[i].parameter}`, parameterValues[i].value);
    }
    return url;
}