import React, {Suspense} from "react";
import {RouteComponentProps, Router} from "@reach/router";
import {StyledEngineProvider, ThemeProvider} from "@mui/material/styles";
import {customizedMaterialTheme} from "./shared/style/theme";
import {SnackbarProvider} from "notistack";
import "./App.css";
import {Provider} from "react-redux";
import {reduxStore} from "./app/redux_store";
import "./SkolarSansPEWeb-Rg.woff2";
import "./SkolarSansPEWeb-Sb.woff2";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ExclusiveModeDetector} from "sales/exclusivemodedetector.component";
import {LayoutArea} from "shared/components/page_layout/layoutarea.component";
import {ApiErrorProvider} from "shared/provider/api_error/api_error.provider";
import {OrderConfigurationProvider} from "shared/provider/orderconfiguration/orderconfiguration.provider";

const App: React.FC<RouteComponentProps> = () => {

    return (
        <Suspense fallback={<div></div>}>
            <Provider store={reduxStore}>
                <ApiErrorProvider>
                    <OrderConfigurationProvider>
                        <ExclusiveModeDetector paths={["/salesorders/", "/salesquotations/"]}/>
                        <StyledEngineProvider injectFirst>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={customizedMaterialTheme}>
                                    <SnackbarProvider anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
                                        <Router
                                            style={{display: "inline"}}>{/* keep router's <div> not to crash layout (min-width: 100%) */}
                                            <LayoutArea default/>
                                        </Router>
                                    </SnackbarProvider>
                                </ThemeProvider>
                            </LocalizationProvider>
                        </StyledEngineProvider>
                    </OrderConfigurationProvider>
                </ApiErrorProvider>
            </Provider>
        </Suspense>
    );
};

export default App;
