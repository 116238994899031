import styled from "styled-components";
import {DataGridPro} from "@mui/x-data-grid-pro";
import colors from "../../../../shared/style/colors";

export const DataGridWrapper = styled.div`
  height: 675px;
  width: 100%;
`;


export const StyledDataGrid = styled(DataGridPro)(() => ({

    "& .MuiDataGrid-columnHeaders, .MuiDataGrid-footerContainer, .MuiDataGrid-columnHeader--moving": {
        backgroundColor: colors.mediSecondaryGray
    },
    "& .MuiDataGrid-row": {
        backgroundColor: colors.white
    },
    "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
        marginTop: "14px"
    }
}));
