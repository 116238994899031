import React, {useEffect} from "react";
import {RouteComponentProps} from "@reach/router";
import {FlexBox, Left, PrimaryButtonComponent, Right} from "../../../../shared/shared";
import {useTranslation} from "react-i18next";
import * as style from "./configuration_navigation.style";


interface ConfigurationNavigationProps extends RouteComponentProps {
    canBack?: boolean;
    back?: () => void;
    canNext?: boolean;
    next?: () => void;
    nextButtonLabel?: string;
    hasPendingChanges?: boolean;
}

export const ConfigurationNavigationComponent: React.FC<ConfigurationNavigationProps> = ({
                                                                                             canBack,
                                                                                             back,
                                                                                             canNext,
                                                                                             next,
                                                                                             nextButtonLabel,
                                                                                             hasPendingChanges,
                                                                                         }) => {
    const {t} = useTranslation();

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.repeat) {
            return;
        }
        if (event.ctrlKey && event.altKey) {
            if (event.key === "ArrowLeft" && canBack) {
                handleBack();
            } else if (event.key === "ArrowRight" && canNext) {
                handleNext();
            }
        }
    };

    const handleBack = () => {
        if (typeof hasPendingChanges !== "undefined") {
            if (hasPendingChanges === true) {
                const leavePage = window.confirm(t("LeavePageUnsavedChangesMessage"));
                if (leavePage) {
                    back && back();
                }
            } else {
                back && back();
            }
        } else {
            back && back();
        }
    };

    const handleNext = () => {
        next && next();
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [canBack, canNext]);

    return (
        <style.NavigationButtonArea>
            <FlexBox>
                <Left>
                    {back ? (
                        <PrimaryButtonComponent key="back" onClick={handleBack} disabled={!canBack}>
                            {t("OrderStepButtonBack")}
                        </PrimaryButtonComponent>
                    ) : (
                        <></>
                    )}
                </Left>
                <Right>
                    {next ? (
                        <PrimaryButtonComponent key="next" onClick={handleNext} disabled={!canNext}>
                            {t(nextButtonLabel ? nextButtonLabel : "OrderStepButtonNext")}
                        </PrimaryButtonComponent>
                    ) : (
                        <></>
                    )}
                </Right>
            </FlexBox>
        </style.NavigationButtonArea>
    );
};
