import React from "react";
import {RouteComponentProps} from "@reach/router";
import {HeaderComponent} from "./header.component";
import {HideOnExclusiveMode} from "sales/hideonexclusivemode.component";
import {MenuComponent} from "./menu.component";
import {ContentComponent} from "./content.component";
import {ErrorHandlerComponent} from "../error_handling/error_handler.component";
import AppRouterComponent from "shared/routing/approuter.component";
import {FooterComponent} from "./footer.component";

export const LayoutArea: React.FC<RouteComponentProps> = () => {
    return (
        <div className="layoutArea">
            <HeaderComponent/>
            <HideOnExclusiveMode>
                <MenuComponent/>
            </HideOnExclusiveMode>
            <ContentComponent>
                <ErrorHandlerComponent>
                    <AppRouterComponent/>
                </ErrorHandlerComponent>
            </ContentComponent>
            <FooterComponent/>
        </div>
    );
};
