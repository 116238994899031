import {GridCellParams, GridColDef} from "@mui/x-data-grid";
import * as React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import i18next, {t} from "i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {GridColumnHeaderParams} from "@mui/x-data-grid-pro";
import {formatDateTime} from "../../../../shared/date/formatting";
import {useNavigate} from "@reach/router";
import {useAppDispatch} from "../../../../app/hook";
import {AppDispatch} from "../../../../app/redux_store";
import {setEventId} from "../redux/configurations_events.slice";

dayjs.extend(utc);

const renderMenu = (params: GridCellParams) => {
    const dispatch: AppDispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const handleIconButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuButtonClick = () => {
        const currentUrl = window.location.href.replace("/events", "/eventdetails");
        const url = `${currentUrl}&eventid=${params.id}`;
        navigate(url);
    };

    return <>
        <IconButton
            aria-controls={`actions-menu-${params.id}`}
            aria-haspopup="true"
            onClick={handleIconButtonClick}
            size="large">
            <MoreVertIcon/>
        </IconButton>
        <Menu
            id={`actions-menu-${params.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={() => {
                    dispatch(setEventId(params.id));
                    handleMenuButtonClick();
                }}>
                <>{t("EventsDisplayJsonButtonLabel")}</>
            </MenuItem>
        </Menu>
    </>;
};

const renderDateTimeLocalized = (params) => {
    return <span>{formatDateTime(params.row.startedAt, i18next.language)}</span>;
};

const renderColumnHeader = (params: GridColumnHeaderParams) => (
    <strong>
        <>
            {t(params.colDef.headerName)}
        </>
    </strong>
);

export const EventGridColumns: GridColDef[] = [
    {
        field: "",
        width: 70,
        disableColumnMenu: true,
        sortable: false,
        hideable: false,
        pinnable: false,
        renderCell: renderMenu
    },
    {
        field: "eventTypeText",
        headerName: "Event",
        renderHeader: renderColumnHeader,
        minWidth: 200,
        sortable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
    },
    {
        field: "startedAt",
        headerName: "EventsStartedAt",
        renderHeader: renderColumnHeader,
        minWidth: 400,
        sortable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
        renderCell: renderDateTimeLocalized
    },
    {
        field: "triggeredBy",
        headerName: "EventsUser",
        renderHeader: renderColumnHeader,
        minWidth: 500,
        sortable: false,
        hideable: false,
        pinnable: false,
        disableColumnMenu: true,
    }
];
