import React from "react";
import {Redirect, Router} from "@reach/router";
import {NotFoundComponent} from "../shared";
import {HomeComponent} from "../../home/home.component";
import {OrderConfigurationsComponent} from "configurations/order/orderconfigurations.component";
import {AdminComponent} from "../../admin/admin.component";
import {QuotationConfigurationsComponent} from "../../configurations/quotation/quotationconfigurations.component";
import {RedirectSalesItemComponent} from "sales/redirectsalesitem.component";

const AppRouterComponent = () => {
    return (
        <Router>
            <HomeComponent path="/home"/>
            <AdminComponent path="/admin/*"/>

            <OrderConfigurationsComponent path="/orderconfigurations/*"/>
            <RedirectSalesItemComponent path="/salesorder/*" targetBasePath="salesorders/orderconfiguration/create"/>
            <RedirectSalesItemComponent path="/order/*" targetBasePath="salesorders/orderconfiguration/create"/>
            <OrderConfigurationsComponent path="/salesorders/*"/>            

            <QuotationConfigurationsComponent path="/quotationconfigurations/*"/>
            <RedirectSalesItemComponent path="/salesquotation/*" targetBasePath="salesquotations/quotationconfiguration/create"/>
            <RedirectSalesItemComponent path="/quotation/*" targetBasePath="salesquotations/quotationconfiguration/create"/>
            <QuotationConfigurationsComponent path="/salesquotations/*"/>

            <NotFoundComponent default/>
            <Redirect from="/" to="/home" noThrow/>
        </Router>
    );
};
export default AppRouterComponent;
