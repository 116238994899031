import React from "react";
import {Container} from "reactstrap";
import styled from "styled-components";
import AccountAreaComponent from "../../../authentication/account_area/account_area.component";
import colors from "../../style/colors";
import mediLogo from "./medi.png";
import {useTranslation} from "react-i18next";
import CustomerDataComponent
    from "../../../configurations/shared/configuration_editor/components/configuration_customerdata.component";
import ReadonlyInfoComponent
    from "../../../configurations/shared/configuration_editor/components/configuration_readonlyInfo.component";
import SalesItemDataComponent
    from "configurations/shared/configuration_editor/components/configuration_salesitemdata.component";
import EventsInfoIconComponent
    from "../../../configurations/shared/configuration_editor/components/configuration_eventsInfoIcon.component";
import ActionsMenuComponent
    from "../../../configurations/shared/configuration_editor/components/configuration_actionsmenu.component";
import {useLocation} from "@reach/router";

const Headline = styled.div`
    font-size: 1.3rem;
    font-weight: bold;
`;

const Logo = styled.img`
    margin-right: 15px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;
const RightAreaWrapper = styled.div`
    margin-left: auto;
    align-content: flex-end;
`;

const HeaderArea = styled.div`
    color: ${colors.mediGray};
    flex-shrink: 0;
    z-index: 40;
    box-shadow: 0px 2px 3px rgb(174 188 197 / 50%);
`;

const LeftRightBox = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;`;

const routesWithOrderDetailInformationInHeader = ["create", "productpreselection", "measurements", "additionsselection", "productinstructions", "completion"];
export const HeaderComponent = () => {

    const {t} = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const configurationId = urlParams.get("orderconfigurationid");
    const location = useLocation();
    const shouldRenderOrderDetailsInHeader = routesWithOrderDetailInformationInHeader.some(path => location.pathname.split("/").includes(path));

    return (
        <HeaderArea>
            <div>
                <Container>
                    <Box>
                        <Logo src={mediLogo} alt="medi logo"/>
                        <Headline>{t("AppName")}</Headline>
                        <RightAreaWrapper>
                            <AccountAreaComponent></AccountAreaComponent>
                        </RightAreaWrapper>
                    </Box>
                    {shouldRenderOrderDetailsInHeader && <LeftRightBox>
                        <CustomerDataComponent></CustomerDataComponent>
                        <RightAreaWrapper>
                            <ReadonlyInfoComponent/>
                            <SalesItemDataComponent orderConfigurationId={configurationId}/>
                            <EventsInfoIconComponent orderConfigurationId={configurationId}/>
                            <ActionsMenuComponent orderConfigurationId={configurationId}/>
                        </RightAreaWrapper>
                    </LeftRightBox>}
                </Container>
            </div>
        </HeaderArea>
    );
};
