import {OrderConfigurationProductLineSelectionState} from "./productlineselection.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";
import IConfigurationProductLineSelectionModel from "models/configurations/configuration_productlineselection";

export const validateState = (state: OrderConfigurationProductLineSelectionState) => {
    // validate input data
    return (doesExist(state.loadedData.productConfiguration) && hasValue(state.loadedData.productConfiguration.id) && state.loadedData.productConfiguration.runId > 0 && hasValue(state.actualData.productLine));
};

export const toCreateModel = (state: OrderConfigurationProductLineSelectionState): IConfigurationProductLineSelectionModel => {
    return {
        productConfiguration: state.loadedData.productConfiguration,
        productLine: state.actualData.productLine,
        serialSize: state.actualData.serialSize
    };
};

export const mustCallApi = (state: OrderConfigurationProductLineSelectionState): boolean => {
    return (state.actualData.productLine !== state.loadedData.productLine
        || state.actualData.serialSize !== state.loadedData.serialSize);
};
