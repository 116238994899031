import React from "react";
import {RouteComponentProps} from "@reach/router";
import {ContentContainer} from "../../shared/components/container/ContentContainer";

export const AdminOverviewComponent: React.FC<RouteComponentProps> = () => {
    return (
        <ContentContainer>
            <div>AdminOverviewComponent</div>
        </ContentContainer>
    );
};
