import * as React from "react";
import {useEffect, useState} from "react";
import {GridColumnMenu, GridColumnMenuProps, GridSortModel} from "@mui/x-data-grid-pro";
import {useAppDispatch, useAppSelector} from "../../../../app/hook";
import {AppDispatch} from "../../../../app/redux_store";
import {getConfigurations, updateConfigurations} from "../redux/configurations_overview.thunks";
import {setFilters, setPagination} from "../redux/configurations_overview.slice";
import {DataGridWrapper, StyledDataGrid} from "./datagrid.style";
import {GridColDef} from "@mui/x-data-grid";
import {ConfigurationMode} from "../../configurationMode.model";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../shared/components/loading_overlay/loading_overlay.component";
import ErrorDialog from "../../../../shared/components/error_dialog/error_dialog.component";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";


interface ConfigurationOverviewComponentProps {
    columnDefinitions: GridColDef[];
}

const sortByCreatedAtDescending: GridSortModel = [
    {
        field: "createdAt",
        sort: "desc",
    },
];

function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Hide `columnMenuColumnsItem`
                columnMenuColumnsItem: null,
            }}
        />
    );
}

const ConfigurationOverviewComponent: React.FC<ConfigurationOverviewComponentProps> = ({columnDefinitions}) => {
    const state = useAppSelector((state) => state.configuration_overview);
    const configurationMode = useAppSelector((state) => state.configuration_mode).mode;
    const dispatch: AppDispatch = useAppDispatch();
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isVisible: false, errorMessage: ""});
    const [isLoading, setIsLoading] = useState(true);
    const {apiError} = useApiError();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        if (configurationMode !== ConfigurationMode.NotSet)
            dispatch(getConfigurations());
    }, [dispatch, configurationMode]);

    useEffect(() => {
        if (configurationMode !== ConfigurationMode.NotSet && state.loadedData.configuration !== null) {
            dispatch(updateConfigurations());
        }
    }, [state.actualData.pagination, state.actualData.filter]);

    useEffect(() => {
        if (state.query.getConfigurations.status === "error") {
            showError(state.query.getConfigurations.message);
            setIsLoading(true);
        } else if (state.query.getConfigurations.status === "pending" || state.query.getConfigurations.status === "idle") {
            setIsLoading(true);
        } else if (state.query.getConfigurations.status === "success") {
            setIsLoading(false);
        }
    }, [state.query.getConfigurations.status]);


    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            <DataGridWrapper>
                                <StyledDataGrid
                                    slots={{columnMenu: CustomColumnMenu}}
                                    slotProps={{
                                        columnsPanel: {
                                            disableHideAllButton: true,
                                            disableShowAllButton: true,
                                        },
                                    }}
                                    checkboxSelection={false}
                                    disableMultipleColumnsFiltering={true}
                                    disableRowSelectionOnClick={true}
                                    columns={columnDefinitions}
                                    paginationMode="server"
                                    filterMode="server"
                                    sortModel={sortByCreatedAtDescending}
                                    rows={state.loadedData.configuration.data}
                                    rowCount={state.loadedData.configuration.totalCount}
                                    pageSizeOptions={[20, 40, 60, 80, 100]}
                                    pagination={true}
                                    paginationModel={state.actualData.pagination}
                                    onPaginationModelChange={(newPaginationModel) =>
                                        dispatch(setPagination(newPaginationModel))
                                    }
                                    onFilterModelChange={(newFilterModel) =>
                                        dispatch(setFilters(newFilterModel))
                                    }
                                />
                            </DataGridWrapper>
                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>
    );
};


export default ConfigurationOverviewComponent;
