import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationStepEnum,} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ConfigurationProductPreSelectionState} from "./productpreselection.model";
import {validateState, validateStateForPostSKU} from "./productpreselection.reducer";
import {
    cloneMtmNumber,
    getConfiguration,
    getPreselectionConfigurationOptions,
    gotoNext,
    preselectProduct,
    preselectProductPostSkU,
    skipPreselectProduct
} from "./productpreselection.thunks";
import {setErrorInState} from "shared/networking/error_handling";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";
import IConfigurationImportModel from "models/configurations/configurationimportmodel";

const initialState: ConfigurationProductPreSelectionState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.ProductPreSelection,
            configurationId: null,
        },
        preselectionConfigurationOptions: null,
        configuration: null,
        isNavigating: false,
    },
    actualData: {
        sku: "",
        selectedFootStyle: null,
        selectedSide: null,
        navigationTarget: null,
        handleStepChange: false,
        isSelectedByFilter: false,
        isReset: false,
        mtmNumber: null,
        importedConfiguration: null,
    },
    command: {
        gotoCreate: {
            status: "idle",
            canExecute: false
        },
        preselect: {
            status: "idle",
            canExecute: false
        },
        preselectPostSKU: {
            status: "idle",
            canExecute: false
        },
        cloneMtmNumber: {
            status: "idle",
            canExecute: false
        },
    },
    error: {
        preselectNoAdditionError: false,
        preselectSKUNotFoundError: false,
        preselectMtmNumberError: false,
        errormessage: "",
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        },
        post: {
            status: "idle",
            canExecute: true
        }
    }
};

export const configurationProductPreSelectionSlice = createSlice({
    name: "configuration/productpreselection",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetStateOnSKUNotFoundError: (state) => {
            state.loadedData.preselectionConfigurationOptions = null;
            state.actualData = initialState.actualData;
            state.loadedData.configuration.sku = null;
            state.loadedData.configuration.productName = null;
        },
        resetPreselect: (state) => {
            state.command.preselect.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetGotoCreate: (state) => {
            state.command.gotoCreate.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            state.loadedData.stepState.targetStep = null;
        },
        resetPreselectNoAdditionError: (state) => {
            state.error.preselectNoAdditionError = false;
            state.error.errormessage = "";
        },
        resetPreselectSKUNotFoundError: (state) => {
            state.error.preselectSKUNotFoundError = false;
            state.error.errormessage = "";
        },
        resetPreselectMtmNumberError: (state) => {
            state.error.preselectMtmNumberError = false;
            state.error.errormessage = "";
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        setSku: (state, action: PayloadAction<string>) => {
            state.actualData.sku = action.payload;
            state.actualData.selectedSide = null;
            state.actualData.selectedFootStyle = null;
            state.command.preselectPostSKU.canExecute = validateStateForPostSKU(state);
            state.command.preselect.canExecute = (action.payload === state.loadedData?.configuration?.sku); // allow continue when unchanged sku
        },
        setSkuAndNavigate: (state, action: PayloadAction<string>) => {
            state.actualData.sku = action.payload;
            state.actualData.selectedSide = null;
            state.actualData.selectedFootStyle = null;
            state.command.preselectPostSKU.canExecute = validateStateForPostSKU(state);
            state.command.preselectPostSKU.goNextAutomatically = true;
        },
        setSide: (state, action: PayloadAction<number>) => {
            state.actualData.selectedSide = action.payload;
            state.command.preselect.canExecute = validateState(state);
        },
        setFootStyle: (state, action: PayloadAction<number>) => {
            state.actualData.selectedFootStyle = action.payload;
            state.command.preselect.canExecute = validateState(state);
        },
        resetSideAndFootStyle: (state) => {
            state.actualData.selectedFootStyle = null;
            state.actualData.selectedSide = null;
            state.command.preselect.canExecute = validateState(state);
        },
        setIsSelectedByFilter: (state, action: PayloadAction<boolean>) => {
            state.actualData.isSelectedByFilter = action.payload;
            state.command.preselect.canExecute = validateState(state);
        },
        setIsReset: (state, action: PayloadAction<boolean>) => {
            state.actualData.isReset = action.payload;
        },
        changeStep: (state, action: PayloadAction<IStepChange>) => {
            state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
        navigateTo: (state, action: PayloadAction<string>) => {
            state.command.preselect.status = "idle";
            state.actualData.navigationTarget = action.payload;
        },
        setMtmNumber: (state, action: PayloadAction<string>) => {
            const mtmNumber = action.payload;
            state.actualData.mtmNumber = mtmNumber;
            state.command.cloneMtmNumber.canExecute = mtmNumber?.length == 10;
        }
    }, extraReducers: (builder) => {
        // gotoCreate
        builder
            // getOrderConfiguration
            .addCase(getConfiguration.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getConfiguration.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getConfiguration.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData.configuration = data;
                state.loadedData.stepState.configurationId = data.id;
                state.actualData.sku = data.sku;
            })

            // getPreselectionConfigurationOptions
            .addCase(getPreselectionConfigurationOptions.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getPreselectionConfigurationOptions.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getPreselectionConfigurationOptions.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData.preselectionConfigurationOptions = data;
                state.command.preselect.canExecute = validateState(state);
            })

            // postSKU
            .addCase(preselectProductPostSkU.pending, (state) => {
                state.query.post.status = "pending";
                state.error.preselectSKUNotFoundError = false;
                state.query.post.canExecute = false;
            })
            .addCase(preselectProductPostSkU.rejected, (state, action) => {
                setErrorInState(state.query.post, action);
                if (action.error.code === "404")
                    state.error.preselectSKUNotFoundError = true;
            })
            .addCase(preselectProductPostSkU.fulfilled, (state, action) => {
                state.query.post.status = "success";
                const data = action.payload.getData();
                state.actualData.sku = data.sku;
                state.loadedData.configuration.sku = data.sku;
                state.loadedData.configuration.productName = data.productName;
                state.loadedData.configuration.productionType = data.productionType;
                state.command.preselectPostSKU.canExecute = validateStateForPostSKU(state);
            })

            // preselectProduct
            .addCase(preselectProduct.rejected, (state, action) => {
                setErrorInState(state.command.preselect, action);
                if (action.error.code === "422") {
                    state.error.preselectNoAdditionError = true;
                    state.error.errormessage = "Es wurden keine Zusätze gefunden, bitte ein anderes Produkt wählen.";
                }
            })
            .addCase(preselectProduct.fulfilled, (state) => {
                state.command.preselect.status = "success";
            })

            // skipPreselectProduct
            .addCase(skipPreselectProduct.fulfilled, (state) => {
                state.command.preselect.status = "success";
            })

            // gotoNext
            .addCase(gotoNext.pending, (state) => {
                state.command.preselect.status = "pending";
                state.command.preselect.canExecute = false;
            })

            .addCase(cloneMtmNumber.pending, (state) => {
                state.command.cloneMtmNumber.status = "pending";
                state.command.cloneMtmNumber.canExecute = false;
            })
            .addCase(cloneMtmNumber.fulfilled, (state, action) => {
                const configData = action.payload.getData() as IConfigurationImportModel;
                state.actualData.importedConfiguration = configData;
            })
            .addCase(cloneMtmNumber.rejected, (state, action) => {
                setErrorInState(state.command.cloneMtmNumber, action);
                state.error.preselectMtmNumberError = true;
            });
    }
});

export const {
    resetState,
    resetStateOnSKUNotFoundError,
    resetPreselect,
    resetGotoCreate,
    resetStepChange,
    resetPreselectNoAdditionError,
    resetPreselectSKUNotFoundError,
    resetPreselectMtmNumberError,
    setIsNavigate,
    setSku,
    setSkuAndNavigate,
    setFootStyle,
    setSide,
    resetSideAndFootStyle,
    changeStep,
    setIsSelectedByFilter,
    setIsReset,
    navigateTo,
    setMtmNumber,
} = configurationProductPreSelectionSlice.actions;

export default configurationProductPreSelectionSlice.reducer;
