import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";

export const selectProduct = createAsyncThunk(
    "configuration/productselection",
    async (_, {getState, rejectWithValue}) => {
        const state = getState() as RootState;
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.selectProduct(state.configuration_productselection.loadedData.orderConfiguration.id, state.configuration_productselection.loadedData.orderConfiguration.sku, mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const getOrderConfiguration = createAsyncThunk(
    "configuration/productselection/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getSelectProduct(id, mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const skipSelectProduct = createAsyncThunk(
    "configuration/productselection/skipSelectProduct",
    // eslint-disable-next-line
    async () => {
    }
);

export const goBack = createAsyncThunk(
    "configuration/productselection/gotoproductlineselection",
    // eslint-disable-next-line
    async () => {
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/productselection/gotonext",
    async () => {
        // const state = getState() as RootState;
        // if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
        //     dispatch(navigateTo({
        //         targetStep: determineNextStep(ConfigurationStepEnum.Create, state.configuration_productselection.loadedData.configuration.productionType),
        //         configurationId: state.configuration_editor.loadedData.configurationId,
        //         productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
        //         productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
        //     }));
        //     return;
        // }
        // if (mustCallApi(state.configuration_productselection)) {
        //     await dispatch(selectProduct());
        // } else {
        //     await dispatch(skipSelectProduct());
        // }
    }
);
