import React, { useEffect, useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { hasValue } from "services/validation.service";
import { useAppDispatch } from "app/hook";
import { AppDispatch } from "app/redux_store";
import { setExclusive } from "configurations/shared/configurationmodeSlice";
import { Guid } from "guid-typescript";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

// /salesorder?inforTenant=TST&inforGroupCompanyNo=101&businessPartner=57309&salesOrderNo=123A&position=1&itemCode=4B40003000&ocid=....

// /order?companyNo=1&customerNo=10000&deliveryAddressNo=0&orderNo=12345&position=1&sku=4B40003000&commission=Max&ocid=...

export interface RedirectSalesItemComponentProps extends RouteComponentProps {
    targetBasePath: string
}

export const RedirectSalesItemComponent: React.FC<RedirectSalesItemComponentProps> = (props) => {

    const { t } = useTranslation();
    const [displayErrorDialog, setDisplayErrorDialog] = useState({ isVisible: false, errorMessages: [] });
    const dispatch: AppDispatch = useAppDispatch();

    const showError = (messages: string[]) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessages: messages
        });
    };

    useEffect(() => {
        dispatch(setExclusive());
    }, []);

    const urlParams = new URLSearchParams(window.location.search);

    const erpTenant = urlParams.get("inforTenant") ?? "medi400";
    const erpGroupCompanyNo = Number(urlParams.get("inforGroupCompanyNo")) || Number(urlParams.get("companyNo")) + 100;
    const customerNo = urlParams.get("businessPartner") ?? urlParams.get("customerNo");
    const deliveryAddressNo = Number(urlParams.get("deliveryAddressNo"));
    const erpItemId = urlParams.get("salesOrderNo") ?? urlParams.get("orderNo");
    const erpItemPosition = Number(urlParams.get("position"));
    const sku = urlParams.get("itemCode") ?? urlParams.get("sku");
    const commission = urlParams.get("commission");
    const ocid = urlParams.get("ocid");
    const mtmNumber = urlParams.get("mtmNo");

    const errors: string[] = [];

    useEffect(() => {

        const searchParams = new URLSearchParams();

        if (hasValue(erpTenant) && hasValue(erpGroupCompanyNo) && hasValue(customerNo) && hasValue(erpItemId) && hasValue(erpItemPosition)) {

            if (isNaN(erpGroupCompanyNo)) {
                errors.push(t("ErpApiErrorGroupCompanyNumber"));
            }

            if (isNaN(erpItemPosition)) {
                errors.push(t("ErpApiErrorPosition"));
            }

            if ((hasValue(sku) || hasValue(commission)) && hasValue(mtmNumber)) {
                errors.push(t("ErpApiErrorParameterConflict")?.replace("{params}", "sku, commission, mtmNo"));
            }

            if (errors.length) {
                showError(errors);
                return;
            }

            searchParams.append("erpTenant", erpTenant);
            searchParams.append("erpGroupCompanyNo", erpGroupCompanyNo.toString());
            searchParams.append("customerNo", customerNo);
            searchParams.appendIf(hasValue(deliveryAddressNo), "deliveryAddressNo", deliveryAddressNo);
            searchParams.append("erpItemId", erpItemId);
            searchParams.append("erpItemPosition", erpItemPosition.toString());

            if (hasValue(mtmNumber)) {
                searchParams.appendIf(hasValue(mtmNumber), "mtmNo", mtmNumber);
            }
            else {
                searchParams.appendIf(hasValue(sku), "sku", sku);
                searchParams.appendIf(hasValue(commission), "commission", commission);
            }
        } else if (hasValue(ocid)) {
            if (!Guid.isGuid(ocid)) {
                errors.push(t("ErpApiErrorGuid"));
                showError(errors);
                return;
            } else {
                searchParams.append("orderconfigurationid", ocid);
            }
        } else {
            console.error("No valid parameters.");
            return;
        }

        const newUrl = `/${props.targetBasePath}?` + searchParams.toString();
        navigate(newUrl);
    }, []);

    return (<>
        {displayErrorDialog.isVisible ? (
            <Dialog open={true}>
                <DialogTitle>{t("AppName")}</DialogTitle>
                <DialogContent>
                    <p>{t("ErpApiErrorSummary")}</p>
                    {
                        displayErrorDialog.errorMessages.map((error, index) => (
                            <p style={{ whiteSpace: "pre-wrap" }} key={index}>{`${error}`}</p>
                        ))}
                </DialogContent>
            </Dialog>) : (<>{t("Loading")}</>)}
    </>);
};
