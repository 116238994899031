import React, {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import {AppDispatch} from "app/redux_store";
import {useAppDispatch, useAppSelector} from "app/hook";
import {
    changeStep,
    goBack,
    resetGotoProductSelection,
    resetRestoreAdditions,
    resetSetAdditions,
    resetState,
    resetStepChange,
    setIsNavigate,
    updateCanEvaluate,
    updateOnAdditionsEvaluated,
    updateOnAdditionsLoaded,
} from "./redux/additions.slice";
import {
    ConfigurationStepperComponent
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {
    ConfigurationNavigationComponent
} from "configurations/shared/configuration_editor/components/configuration_navigation.component";
import {getAdditionsSelection, gotoNext, setAdditions} from "./redux/additions.thunks";
import {getConfig} from "services/config.service";
import {canGotoNext} from "./redux/additions.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import {INavigationParams} from "configurations/shared/configuration_editor/models/navigation_params.model";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {AdditionsWrapperComponent} from "./components/addition_wrapper.component";
import {ContentContainer} from "shared/components/container/ContentContainer";
import {
    ProductInformation
} from "configurations/shared/configuration_editor/components/configuration_productinformation.component";
import IConfiguration from "../../../../../models/configurations/configuration";
import ErrorDialog from "../../../../../shared/components/error_dialog/error_dialog.component";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../../shared/components/loading_overlay/loading_overlay.component";
import {updateAvailableSteps, updateStepperState} from "../../redux/stepper.slice";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import {CommonApiResultError} from "shared/networking/common_api_result";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";

interface AdditionsProps extends RouteComponentProps {
    configurationId: string;
    onNavigate: (next: StepDescriptor, params?: INavigationParams[]) => void;
    getCustomerData: (configuration: IConfiguration) => void;
    configurationState: ConfigurationStepEnum;
}

export const AdditionsComponent: React.FC<AdditionsProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const additionsRef = useRef(null);
    const state = useAppSelector((state) => state.configuration_additions);
    const stepperState = useAppSelector((state) => state.configuration_stepper);
    const [displayErrorDialog, setDisplayErrorDialog] = useState({isVisible: false, errorMessage: ""});
    const [isLoading, setIsLoading] = useState(true);
    const [isMTMComponentLoading, setIsMTMComponentLoading] = useState(true);
    const {apiError, setApiError} = useApiError();
    const {hasPendingChanges, setPendingChanges} = usePendingChanges();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(updateStepperState(ConfigurationStepEnum.AdditionsSelection));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }; // on exit component
    }, [dispatch]);

    useEffect(() => {
        if (props.configurationId !== undefined && props.configurationId !== null) {
            dispatch(getAdditionsSelection(props.configurationId));
        }
    }, [props.configurationId]);

    useEffect(() => {
        if (state.loadedData?.configuration) {
            props.getCustomerData(state.loadedData.configuration);
            setIsLoading(false);
        } else {
            setPendingChanges(false);
            setIsLoading(true);
        }
    }, [state.loadedData]);

    useEffect(() => {
        if (state.loadedData.configuration !== null) {
            if (state.loadedData.configuration.productionType !== null && state.loadedData.configuration.productionType !== undefined) {
                dispatch(updateAvailableSteps(state.loadedData.configuration.productionType));
            }
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        } else {
            hideError();
        }
    }, [state.query.get.status]);

    useEffect(() => {
        if (state.command.goBackToProductSelect.status === "success") {
            const target = stepperState.previousStep;
            dispatch(setIsNavigate());
            props.onNavigate(target, [
                {name: "productorderconfigurationid", value: state.actualData.productConfigurationId},
                {name: "productorderconfigurationrunid", value: state.actualData.productConfigurationRunId},
            ]);
            dispatch(resetGotoProductSelection());
        }
    }, [state.command.goBackToProductSelect.status]);

    useEffect(() => {
        if (state.command.setAdditions.status === "success") {
            const target = stepperState.nextStep;
            dispatch(setIsNavigate());
            props.onNavigate(target, [
                {name: "productorderconfigurationid", value: state.actualData.productConfigurationId},
                {name: "productorderconfigurationrunid", value: state.actualData.productConfigurationRunId},
            ]);
            dispatch(resetSetAdditions());
        }
        if (state.command.setAdditions.status === "error") {
            dispatch(resetSetAdditions());
            showError(state.command.setAdditions.message);
        }
    }, [state.command.setAdditions.status]);

    useEffect(() => {
        if (state.actualData.doSetAdditions) {
            dispatch(setAdditions());
        }
    }, [state.actualData.doSetAdditions]);

    useEffect(() => {
        if (state.actualData.handleStepChange === true) {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(updateStepperState(target.value));
            dispatch(resetStepChange());
        }
    }, [state.actualData.handleStepChange]);

    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            {
                                (state.loadedData.configuration?.sku !== null) &&
                                <ProductInformation
                                    sku={state.loadedData.configuration.sku}
                                    skuLongIdentifier={state.loadedData.configuration.productName}/>
                            }

                            <ConfigurationStepperComponent
                                onStepChangeExecute={(step: IStepChange) => dispatch(changeStep(step))}
                                orderConfigurationStateStep={props.configurationState}/>

                            <LoadingOverlayContainer>
                                {isMTMComponentLoading && <LoadingOverlayComponent></LoadingOverlayComponent>}

                                <ContentContainer>
                                    <AdditionsWrapperComponent
                                        ref={additionsRef}
                                        mtmApiEndpointBase={getConfig().mtmService.baseUrl}
                                        configuration={state.loadedData.configuration}
                                        resetRestoreAdditions={() => dispatch(resetRestoreAdditions())}
                                        updateAdditionsEvaluated={(details) => {
                                            dispatch(updateOnAdditionsEvaluated(details.detail));
                                            setIsMTMComponentLoading(false);
                                        }}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        updateAdditionsEvaluating={() => {
                                            setIsMTMComponentLoading(true);
                                        }}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        updateAdditionsEvaluationFailed={(details) => {
                                            setApiError(details.detail.error as unknown as CommonApiResultError);
                                            showError("");
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateAdditionsLoading={() => {
                                            setIsMTMComponentLoading(true);
                                        }}
                                        updateAdditionsLoadingFailed={(details) => {
                                            setApiError(details.detail.error as unknown as CommonApiResultError);
                                            showError("");
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateAdditionsLoaded={(details) => {
                                            if (details.detail.componentData.isModified)
                                                setPendingChanges(true);
                                            
                                            dispatch(updateOnAdditionsLoaded(details.detail));
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateGetComponentState={(details) => {
                                            if (details.detail.isModified)
                                                setPendingChanges(true);

                                            // todo update ComponentState
                                            dispatch(updateCanEvaluate(details.detail));
                                        }}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        changeEvaluationStateByEvaluationReceivedAdditions={() => {
                                        }}                                        
                                    />

                                    <ConfigurationNavigationComponent
                                        canBack={state.loadedData.configuration != null}
                                        back={() => dispatch(goBack(stepperState.previousStep))}
                                        canNext={canGotoNext(state)}
                                        next={() => dispatch(gotoNext(additionsRef))}
                                        hasPendingChanges={hasPendingChanges}
                                    />
                                </ContentContainer>
                            </LoadingOverlayContainer>

                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>
    );
};
