import {RouteComponentProps, Router, useNavigate} from "@reach/router";
import {useAppDispatch, useAppSelector} from "app/hook";
import {AppDispatch} from "app/redux_store";
import React, {useEffect, useState} from "react";
import CreateComponent from "./steps/create/create.component";
import {MeasurementsComponent} from "./steps/measurements/measurements.component";
import {ProductPreSelectionComponent} from "./steps/productpreselection/productpreselection.component";
import {resetNavigateTo, resetState} from "./redux/editor.slice";
import {getConfiguration, getOrderConfigurationValidationState, navigateTo} from "./redux/editor.thunks";
import {StringParam, useQueryParam} from "use-query-params";
import {StepDescriptor} from "./models/step_descriptor.model";
import {INavigationParams} from "./models/navigation_params.model";
import {AdditionsComponent} from "./steps/additions/additions.component";
import {ProductInstructionsComponent} from "./steps/productinstructions/productinstructions.component";
import {CompletionComponent} from "./steps/completion/completion.component";
import {loadCustomerData} from "./redux/customerdata.thunk";
import IConfiguration from "../../../models/configurations/configuration";
import {useTranslation} from "react-i18next";
import {URLHelper} from "shared/extensions/URLHelper";
import {releaseLock} from "./steps/completion/redux/completion.thunks";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";
import { ConfigurationMode } from "../configurationMode.model";
import ProductConfigurationState from "models/configurations/productconfigurationstate.enum";
import { determineTargetStep } from "./redux/editor.reducer";
import { ConfigurationState } from "models/configuration_state.enum";
import { LoadingOverlayComponent } from "shared/components/loading_overlay/loading_overlay.component";
import {Dialog, DialogContent, DialogTitle } from "@mui/material";
import { formatDate } from "shared/date/formatting";
import i18next from "i18n/i18n";
import { ConfigurationStepEnum } from "./models/configuration_step.enum";

export const EditorComponent: React.FC<RouteComponentProps> = () => {

    const dispatch: AppDispatch = useAppDispatch();
    const navigate = useNavigate();
    const state = useAppSelector((state) => state.configuration_editor);
    const [configurationid] = useQueryParam("orderconfigurationid", StringParam);
    const customerDataState = useAppSelector((state) => state.configuration_customerData);
    const configurationMode = useAppSelector((state) => state.configuration_mode).mode;
    const {t} = useTranslation();
    const [targetStep, setTargetStep] = useState(ConfigurationStepEnum.Create);

    const {hasPendingChanges, setPendingChanges} = usePendingChanges();

    const doNavigate = (newStep: StepDescriptor, params?: INavigationParams[]) => {
        let configurationId = state.loadedData.configurationId;
        let productConfigId = undefined;
        let productConfigRunId = undefined;
        if (params) {
            if (params.filter(x => x.name === "orderconfigurationid").length === 1) {
                configurationId = params.filter(x => x.name === "orderconfigurationid")[0].value;
            }
            if (params.filter(x => x.name === "productorderconfigurationid").length === 1) {
                productConfigId = params.filter(x => x.name === "productorderconfigurationid")[0].value;
            }
            if (params.filter(x => x.name === "productorderconfigurationrunid").length === 1) {
                productConfigRunId = params.filter(x => x.name === "productorderconfigurationrunid")[0].value;
            }
        }

        dispatch(navigateTo({
            targetStep: newStep,
            configurationId: configurationId,
            productConfigurationId: productConfigId,
            productConfigurationRunId: productConfigRunId
        }));
    };

    const getCustomerData = (orderConfiguration: IConfiguration) => {
        if (
            orderConfiguration?.erpGroupCompanyNumber !== null &&
            orderConfiguration.customerNumber !== null &&
            customerDataState.loadedData === undefined &&
            !isNaN(Number(orderConfiguration.customerNumber))
        ) {
            dispatch(loadCustomerData({
                erpGroupCompanyNumber: orderConfiguration.erpGroupCompanyNumber,
                customerNumber: Number(orderConfiguration.customerNumber),
                deliveryAddressNumber: orderConfiguration.deliveryAddressNumber
            }));
        }
    };

    const getOrderConfiguration = () => {
        return state.loadedData.configurationId;
    };

    useEffect(() => {

        const releaseLockOnUnload = () => {
            dispatch(releaseLock(configurationid));
        };
        window.addEventListener("unload", releaseLockOnUnload); // ensure lock release if page is closed

        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = "";
        };

        const handlePopState = () => {
            alert(t("LeavePageUnsavedChangesMessage"));
        };

        const handleUnload = () => {
            dispatch(releaseLock(configurationid));
        };

        if (!hasPendingChanges) {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handlePopState);
            window.removeEventListener("unload", handleUnload);
        } else {
            window.addEventListener("beforeunload", handleBeforeUnload);
            window.addEventListener("popstate", handlePopState);
            window.addEventListener("unload", handleUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handlePopState);
            window.removeEventListener("unload", handleUnload);
        };
    }, [hasPendingChanges]);

    useEffect(() => {
        return () => {
            setPendingChanges(false);
        }; // on exit component
    }, []);

    useEffect(() => {
        if (configurationid && state.loadedData.configurationId !== configurationid && configurationMode != ConfigurationMode.NotSet) {
            dispatch(getConfiguration(configurationid));
        }
    }, [configurationid, configurationMode]);

    useEffect(() => {
        if (state.loadedData.configurationId && configurationMode != ConfigurationMode.NotSet) {
            dispatch(getOrderConfigurationValidationState());
        }
    }, [state.loadedData.configurationId, configurationMode]);

    useEffect(() => {
        if (state.loadedData.orderConfigurationState) {
            let targetStep : StepDescriptor;

            if (!state.loadedData.orderConfigurationState.productState.productEnabled && state.loadedData.orderConfigurationState.orderConfigurationState < ConfigurationState.Finalized)
            {
                return;
            }

            if (state.loadedData.orderConfigurationState.readonly)
            {
                return;
            }

            if (state.loadedData.orderConfigurationState.massMaskState && ![ProductConfigurationState.UpToDate, ProductConfigurationState.NotExecuted].includes(state.loadedData.orderConfigurationState.massMaskState.globalState))
            {
                if (state.loadedData.orderConfigurationState.massMaskState.globalState == ProductConfigurationState.Deleted)
                    targetStep = determineTargetStep({ orderConfigurationId : configurationid, state: ConfigurationState.InProgressProductPreselected, productionType: state.loadedData.orderConfigurationState.productionType});
                else
                    targetStep = determineTargetStep({ orderConfigurationId : configurationid, state: ConfigurationState.InProgressMeasured, productionType: state.loadedData.orderConfigurationState.productionType});
            }
            else if (state.loadedData.orderConfigurationState.additionState && state.loadedData.orderConfigurationState.additionState.globalState != ProductConfigurationState.UpToDate)
            {
                let targetState: ConfigurationState;

                if (!state.loadedData.orderConfigurationState.massMaskState) // custom design
                {
                    // custom design
                    targetState = ConfigurationState.InProgressProductPreselected;
                }
                else if (state.loadedData.orderConfigurationState.additionState.globalState == ProductConfigurationState.Deleted)
                    targetState = ConfigurationState.InProgressMeasured;
                else
                    targetState = ConfigurationState.InProgressAdditionsSelected;

                targetStep = determineTargetStep({ orderConfigurationId : configurationid, state: targetState, productionType: state.loadedData.orderConfigurationState.productionType});
            }
            else if (state.loadedData.orderConfigurationState.productionInstructionState && state.loadedData.orderConfigurationState.productionInstructionState.globalState != ProductConfigurationState.UpToDate)
            {
                if (state.loadedData.orderConfigurationState.productionInstructionState.globalState == ProductConfigurationState.Deleted)
                    targetStep = determineTargetStep({ orderConfigurationId : configurationid, state: ConfigurationState.InProgressAdditionsSelected, productionType: state.loadedData.orderConfigurationState.productionType});
                else
                    targetStep = determineTargetStep({ orderConfigurationId : configurationid, state: ConfigurationState.InProgressProductionInstructionsSelected, productionType: state.loadedData.orderConfigurationState.productionType});
            }

            if (targetStep)
            {
                // navigate on validation error
                const url = URLHelper.getURL(targetStep.url);
                url.searchParams.append("orderconfigurationid", configurationid);
                navigate(url.toStringRelativeLast());
            }
        }
    }, [state.validateProductConfiguration.get.status]);

    useEffect(() => {
        if (state.loadedData.orderConfigurationState)
        {
            setTargetStep(determineTargetStep({ orderConfigurationId: configurationid, state: state.loadedData.orderConfigurationState.orderConfigurationState }).value);
        }
    }, [state.loadedData.orderConfigurationState]);

    useEffect(() => {
        return () => {
            dispatch(releaseLock(configurationid));
            dispatch(resetState());
        }; // on exit component
    }, [dispatch]);

    useEffect(() => {
        if (state.command.navigateTo.status === "success") {
            dispatch(resetNavigateTo());

            if (typeof state.loadedData.step !== "undefined") {
                const url = URLHelper.getURL(state.loadedData.step.url);
                url.searchParams.append("orderconfigurationid", state.loadedData.configurationId);
                navigate(url.toStringRelativeLast());
            }
        }
    }, [state.command.navigateTo.status]);

    if (state.validateProductConfiguration.get.status === "success" && !state.loadedData.orderConfigurationState.productState.productEnabled && state.loadedData.orderConfigurationState.orderConfigurationState != ConfigurationState.New && state.loadedData.orderConfigurationState.orderConfigurationState < ConfigurationState.Finalized) {
        const releaseDate = state.loadedData.orderConfigurationState.productState.releaseDate ? formatDate(state.loadedData.orderConfigurationState.productState.releaseDate, i18next.language) : null;
        const expirationDate = state.loadedData.orderConfigurationState.productState.expirationDate ? formatDate(state.loadedData.orderConfigurationState.productState.expirationDate, i18next.language) : null;

        const productIsLockedMsg = t("ERROR:ORS_00000011").replace("${sku}", state.loadedData.orderConfigurationState.productState.sku);
        const pleaseCloseWindowMsg = t("PleaseCloseWindow");
        const releaseDateMsg = t("COMMON:ProductReleaseDate");
        const expirationDateMsg = t("COMMON:ProductExpirationDate");

        let errorMessage = productIsLockedMsg;

        if (releaseDate)
            errorMessage += `\n${releaseDateMsg}: ${releaseDate}`;

        if (expirationDate)
            errorMessage += `\n${expirationDateMsg}: ${expirationDate}`;

        errorMessage += `\n\n${pleaseCloseWindowMsg}`;

        return (<Dialog open={true}>
            <DialogTitle>{t("AppName")}</DialogTitle>
            <DialogContent><p style={{whiteSpace: "pre-wrap"}}>{errorMessage}</p></DialogContent>
        </Dialog>);
    }

    return (
        <>
        { state.validateProductConfiguration.get.status === "pending" && <LoadingOverlayComponent message={t("Loading")} /> }
        <Router>
            <CreateComponent
                path="/create"
                onNavigate={(next, params) => doNavigate(next, params)}
                configurationState={targetStep}/>
            <ProductPreSelectionComponent
                path="/productpreselection"
                configurationId={getOrderConfiguration()}
                onNavigate={(next) => doNavigate(next)}
                getCustomerData={getCustomerData}
                isConfigurationReadonly={state.loadedData.orderConfigurationState?.readonly}
                configurationState={targetStep}/>
            <MeasurementsComponent
                path="/measurements"
                configurationId={getOrderConfiguration()}
                onNavigate={doNavigate}
                getCustomerData={getCustomerData}
                configurationState={targetStep}/>
            <AdditionsComponent
                path="/additionsselection"
                configurationId={getOrderConfiguration()}
                onNavigate={doNavigate}
                getCustomerData={getCustomerData}
                configurationState={targetStep}/>
            <ProductInstructionsComponent
                path="/productinstructions"
                configurationId={getOrderConfiguration()}
                onNavigate={doNavigate}
                getCustomerData={getCustomerData}
                configurationState={targetStep}/>
            <CompletionComponent
                path="/completion"
                configurationId={getOrderConfiguration()}
                onNavigate={doNavigate}
                getCustomerData={getCustomerData}
                isConfigurationReadonly={state.loadedData.orderConfigurationState?.readonly}
                configurationState={targetStep}/>
        </Router>
        </>
    );
};
