import {Link} from "@reach/router";
import React from "react";
import {Container} from "reactstrap";
import colors from "shared/style/colors";
import styled from "styled-components";
import MenuRoutes from "../../routing/menu_routes";
import {useTranslation} from "react-i18next";
import { usePendingChanges } from "shared/provider/orderconfiguration/orderconfiguration.provider";


const Box = styled.div`
  display: flex;
  align-items: center;
`;

const MenuArea = styled.div`
  border-top: 1px solid ${colors.mediLightGray};
  flex-shrink: 0;
  z-index: 30;
  position: relative;
  box-shadow: 0px 2px 3px rgb(174 188 197 / 50%);
`;

const LinkWrapper = styled.div`
  text-decoration: none;
  margin-right: 20px;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
  color: ${colors.mediGray};
  font-weight: bold;

  :hover :focus :active {
    color: ${colors.mediMagenta};
    border-bottom: 1px solid ${colors.mediMagenta};
    cursor: pointer;
    text-decoration: none;
  }

  a {
    text-decoration: none;
    padding-bottom: 12px;
  }
`;

interface ActivatableNavLinkProps {
    to: string,
    hasPendingChanges: boolean,
    children: React.ReactNode,
}

const ActivatableNavLink : React.FC<ActivatableNavLinkProps> = (props) => {

    const {t} = useTranslation();

    return (
    <LinkWrapper>
        <Link
            {...props}
            getProps={({isPartiallyCurrent}) => {
                // the object returned here is passed to the
                // anchor element's props
                return {
                    style: {
                        color: isPartiallyCurrent ? colors.mediMagenta : colors.mediGray,
                        borderBottom: isPartiallyCurrent
                            ? `2px solid ${colors.mediMagenta}`
                            : "none",
                        paddingBottom: "13px",
                    },
                };
            }}
            onClick={(event) => {
                let doLeave = true;
                if (props.hasPendingChanges)
                    doLeave = window.confirm(t("LeavePageUnsavedChangesMessage"));

                if (!doLeave)
                    event.preventDefault();

                return doLeave;
            }}
        />
    </LinkWrapper>
);
};

export const MenuComponent = () => {

    const {t} = useTranslation();
    const {hasPendingChanges} = usePendingChanges();

    return (
        <MenuArea>
            <Container>
                <Box>
                    {MenuRoutes.map((entry) => (
                        <ActivatableNavLink key={t(entry.name) as React.Key} to={entry.target} hasPendingChanges={hasPendingChanges}>
                            {t(entry.name)}
                        </ActivatableNavLink>
                    ))}
                </Box>
            </Container>
        </MenuArea>
    );
};
