import {ConfigurationProductInstructionsState} from "./productinstructions.model";
import {doesExist, hasValue} from "../../../../../../services/validation.service";
import { ComponentDataEvent } from "@medi-mtm/components";

export const validateState = (state: ConfigurationProductInstructionsState) => {
    // validate input data
    return (doesExist(state.loadedData.configuration)
        && hasValue(state.actualData.productConfigurationId)
        && state.actualData.productConfigurationRunId != null);
};

export const mustCallApi = (state: ConfigurationProductInstructionsState): boolean => {
    return (state.loadedData.configuration?.productConfigurationId !== state.actualData.productConfigurationId
        ||
        state.loadedData.configuration?.productConfigurationRunId !== state.actualData.productConfigurationRunId);
};

export const canGotoNext = (state: ConfigurationProductInstructionsState): boolean => {
    return (state.command.evaluateProductInstructions.canExecute || state.command.setProductInstructions.canExecute);
};

export const updateComponentData = (state: ConfigurationProductInstructionsState, data: ComponentDataEvent) => {
    state.actualData.isValid = data.isValid;
    state.actualData.productConfigurationId = data.productConfigurationId;
    state.actualData.productConfigurationRunId = data.productConfigurationRunId;
};

export const calculateEvaluateCanExecute = (state: ConfigurationProductInstructionsState): boolean => {
    return state.actualData.isValid;
};
