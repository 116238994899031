import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OrderConfigurationProductLineSelectionState} from "./productlineselection.model";
import {validateState} from "./productlineselection.reducer";
import {
    getOrderConfiguration,
    goBack,
    gotoNext,
    selectProductLine,
    skipSelectProductline
} from "./productlineselection.thunks";
import {setErrorInState} from "shared/networking/error_handling";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";

const initialState: OrderConfigurationProductLineSelectionState = {
    loadedData: {
        productConfiguration: null,
        productLine: null,
        serialSize: null,
        // stepState: {
        //     currentStep: OrderConfigurationStepEnum.ProductLineSelection,
        //     configurationId: null,
        // },
        orderConfiguration: null,
        isNavigating: false,
    },
    actualData: {
        productLine: null,
        serialSize: null,
        handleStepChange: false,
    },
    command: {
        gotoMeasurements: {
            status: "idle",
            canExecute: false
        },
        selectProductLine: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationProductLineSelectionSlice = createSlice({
    name: "configuration/productlineselection",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetGotoProductSelection: (state) => {
            state.command.selectProductLine.status = "idle";
            // state.loadedData.stepState.targetStep = null;
        },
        resetGotoMeasurements: (state) => {
            state.command.gotoMeasurements.status = "idle";
            // state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            // state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        setProductLine: (state, action: PayloadAction<string>) => {
            state.actualData.productLine = action.payload;
        },
        setSerialSize: (state, action: PayloadAction<string>) => {
            state.actualData.serialSize = action.payload;
        },
        changeStep: (state) => {
            // state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
    }, extraReducers: (builder) => {
        // gotoMeasurements
        builder.addCase(goBack.pending, (state) => {
            state.command.gotoMeasurements.status = "pending";
        }).addCase(goBack.fulfilled, (state) => {
            state.command.gotoMeasurements.status = "success";
            // state.loadedData.stepState.targetStep = OrderConfigurationStepValues[state.loadedData.stepState.currentStep - 1];
        })

            // getOrderConfiguration
            .addCase(getOrderConfiguration.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            }).addCase(getOrderConfiguration.rejected, (state, action) => {
            setErrorInState(state.query.get, action);
        }).addCase(getOrderConfiguration.fulfilled, (state, action) => {
            state.query.get.status = "success";

            const data = action.payload.getData();
            const dataModel = {
                id: data.productConfigurationId,
                runId: data.productConfigurationRunId
            };

            state.loadedData.orderConfiguration = data;
            state.loadedData.productConfiguration = dataModel;
            state.loadedData.productLine = data.productLine;
            state.actualData.productLine = data.productLine;
            state.loadedData.serialSize = data.serialSize;
            state.actualData.serialSize = data.serialSize;
            state.command.selectProductLine.canExecute = validateState(state);
        })

            // selectProductLine
            .addCase(selectProductLine.rejected, (state, action) => {
                setErrorInState(state.command.selectProductLine, action);
            }).addCase(selectProductLine.fulfilled, (state) => {
            state.command.selectProductLine.status = "success";
            // state.loadedData.stepState.targetStep = OrderConfigurationStepValues[state.loadedData.stepState.currentStep + 1];
        })

            // skipSelectProductline
            .addCase(skipSelectProductline.fulfilled, (state) => {
                state.command.selectProductLine.status = "success";
                // state.loadedData.stepState.targetStep = OrderConfigurationStepValues[state.loadedData.stepState.currentStep + 1];
            })

            // gotoNext
            .addCase(gotoNext.pending, (state) => {
                state.command.selectProductLine.status = "pending";
                state.command.selectProductLine.canExecute = false;
            });
    }
});

export const {
    resetState,
    resetGotoProductSelection,
    resetGotoMeasurements,
    resetStepChange,
    setIsNavigate,
    changeStep,
    setProductLine,
    setSerialSize
} = configurationProductLineSelectionSlice.actions;

export default configurationProductLineSelectionSlice.reducer;
