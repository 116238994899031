import React from "react";
import {RouteComponentProps, Router} from "@reach/router";
import {AdminBreadcrumbs} from "./admin.breadcrumbs";
import * as style from "../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {AdminOverviewComponent} from "./overview/AdminOverviewComponent";

export const AdminComponent: React.FC<RouteComponentProps> = () => {
    return (
        <>
            <style.BreadcrumbArea>
                <Container>
                    <AdminBreadcrumbs/>
                </Container>
            </style.BreadcrumbArea>
            <Router>
                <AdminOverviewComponent path="/"/>
            </Router>
        </>
    );
};

