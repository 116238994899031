import React from "react";
import {ThumbnailModel} from "../../../models/thumbnail.model";
import styled from "styled-components";
import {useNavigate} from "@reach/router";

const ThumbnailContainer = styled.div`
  cursor: pointer;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  img {
    transform: scale(1);
    transition: all 600ms ease-in-out;
  }

  :hover img {
    transform: scale(1.15);
    transition: all 600ms ease-in-out;
  }
`;
const ImageContainer = styled.div`
  overflow: hidden;
`;
const FooterContainer = styled.div`
  background-color: #e20074;
  color: white;
  height: auto;
  border: 2px solid #e20074;
  padding: .75rem;
  line-height: 1;
`;

interface IThumbnailComponent {
    thumbnail: ThumbnailModel
}

export const ThumbnailComponent: React.FC<IThumbnailComponent> = ({thumbnail}) => {
    const navigate = useNavigate();

    return (
        <ThumbnailContainer onClick={() => navigate(thumbnail.url)}>
            <ImageContainer>
                <img loading="lazy" width="100%" src={thumbnail.imagePath} alt={thumbnail.imagePath}></img>
            </ImageContainer>
            <FooterContainer>
                {thumbnail.name}
            </FooterContainer>
        </ThumbnailContainer>
    );
};