import {RouteComponentProps} from "@reach/router";
import React, {useEffect, useState} from "react";
import {getFilter} from "./redux/filter.thunk";
import {useAppDispatch, useAppSelector} from "../../../../../../app/hook";
import {FilterComponent} from "./filter.component";
import {resetState} from "./redux/filter.slice";
import ErrorDialog from "../../../../../../shared/components/error_dialog/error_dialog.component";
import {useApiError} from "../../../../../../shared/provider/api_error/api_error.provider";
import {useTranslation} from "react-i18next";
import {ErrorDialogButtons} from "../../../../../../shared/components/error_dialog/error_dialog.helper";
import {setIsReset} from "../redux/productpreselection.slice";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer
} from "../../../../../../shared/components/loading_overlay/loading_overlay.component";
import {PrimaryButtonComponent} from "../../../../../../shared/components/buttons/primary_button.component";
import {Left, Right} from "../../../../../../shared/style/alignment";
import {FilterFlexboxStyle} from "./filter.flexbox.style";

interface FilterWrapperProps extends RouteComponentProps {
    disabled: boolean
}

export const FilterWrapperComponent: React.FC<FilterWrapperProps> = (props) => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state.filter);
    const {t} = useTranslation();
    const {apiError} = useApiError();

    useEffect(() => {
        if (state.filterId === "") {
            dispatch(getFilter("68ef4b48-3cfc-4cef-bb0b-9b5333c006f2"));
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
            dispatch(getFilter("68ef4b48-3cfc-4cef-bb0b-9b5333c006f2"));
        };
    }, [dispatch]);


    const [displayErrorDialog, setDisplayErrorDialog] = useState({
        isVisible: false,
        errorMessage: "",
    });

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        } else {
            hideError();
        }
    }, [state.query.get.status]);

    useEffect(() => {
        if (state.productSKUs) {
            if (state.productSKUs.length === 0) {
                setDisplayErrorDialog({
                    isVisible: true,
                    errorMessage: t("filter.noSkuFoundError").toString()
                });
            } else if (state.productSKUs.length > 1) {
                setDisplayErrorDialog({
                    isVisible: true,
                    errorMessage: t("filter.multipleSkusFoundError \n " + state.productSKUs.join("\n")).toString()
                });
            }
        }
    }, [state.productSKUs]);

    const handleFilterReset = () => {
        dispatch(resetState());
        dispatch(getFilter("68ef4b48-3cfc-4cef-bb0b-9b5333c006f2"));
    };

    return (
        <>
            {displayErrorDialog.isVisible && (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            )}

            <LoadingOverlayContainer>
                <>
                    {
                        state.query.get.status === "pending" && !displayErrorDialog.isVisible &&
                        <LoadingOverlayComponent/>
                    }

                    {state.filterGroups.length > 0 && (
                        <>
                            {state.filterGroups.map((filterGroup, index) => (
                                <div key={filterGroup.displayName}>
                                    <FilterComponent filterGroupId={filterGroup.filterGroupId}
                                                     isFirstFilterComponent={index === 0}
                                                     disabled={props.disabled}/>
                                </div>
                            ))}

                            <FilterFlexboxStyle>
                                <Left>
                                    <PrimaryButtonComponent
                                        disabled={props.disabled}
                                        onClick={() => {
                                            dispatch(setIsReset(true));
                                            handleFilterReset();
                                        }}
                                    >
                                        {t("Filter.ResetFilter")}
                                    </PrimaryButtonComponent>
                                </Left>
                                <Right>
                                    {state.productSKUs && state.productSKUs.length === 1 && (
                                        <>{t("filter.FoundSKUText") + " " + state.productSKUs[0]}</>
                                    )}
                                </Right>
                            </FilterFlexboxStyle>
                        </>
                    )}
                </>
            </LoadingOverlayContainer>
        </>
    );
};
