import React, {useEffect} from "react";
import {RouteComponentProps} from "@reach/router";
import {ContentContainer} from "../../../../shared/components/container/ContentContainer";
import ConfigurationOverviewComponent
    from "../../../shared/configuration_overview/components/configuration_overview.component";
import * as styleBreadcrumbs from "../../../../shared/breadcrumbs/breadcrumbs_root.style";
import {Container} from "reactstrap";
import {OrderConfigurationsBreadcrumbs} from "../../orderconfigurations.breadcrumbs";
import {AppDispatch} from "../../../../app/redux_store";
import {useAppDispatch} from "../../../../app/hook";
import {setBasePath, setMode} from "../../../shared/configurationmodeSlice";
import {ConfigurationMode} from "../../../shared/configurationMode.model";
import {getBasePath} from "../../../shared/basePathGetter";
import {OrderconfigurationOverviewColumns} from "./columndefinitions/orderconfiguration_overview_columns";

export const OrderConfigurationOverviewComponent: React.FC<RouteComponentProps> = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.OrderConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    <OrderConfigurationsBreadcrumbs/>
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <ConfigurationOverviewComponent columnDefinitions={OrderconfigurationOverviewColumns}/>
            </ContentContainer>
        </>
    );
};
