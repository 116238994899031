import {RouteComponentProps, Router} from "@reach/router";
import React, {useEffect} from "react";
import {QueryParamProvider} from "use-query-params";
import {ReachAdapter} from "use-query-params/adapters/reach";
import {parse, stringify} from "query-string";
import {EditorComponent} from "../shared/configuration_editor/editor.component";
import {QuotationConfigurationsMenuComponent} from "./quotationconfiguration_menu/quotationconfigurationmenu.component";
import {setBasePath, setMode} from "../shared/configurationmodeSlice";
import {ConfigurationMode} from "../shared/configurationMode.model";
import {getBasePath} from "../shared/basePathGetter";
import {AppDispatch} from "../../app/redux_store";
import {useAppDispatch} from "../../app/hook";
import {
    QuotationConfigurationOverviewComponent
} from "./quotationconfiguration_overview/components/quotationconfiguration_overview.component";
import ConfigurationProductionOrderComponent
    from "../shared/configuration_productionorder/components/configuration_productionorder.component";
import {QuotationConfigurationsBreadcrumbs} from "./quotationconfigurations.breadcrumbs";
import ConfigurationEventsComponent
    from "configurations/shared/configuration_events/components/configuration_events.component";
import ConfigurationEventJsonComponent
    from "configurations/shared/configuration_events/components/configuration_eventJson.component";


export const QuotationConfigurationsComponent: React.FC<RouteComponentProps> = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.QuotationConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);

    return (
        <>
            <QueryParamProvider
                adapter={ReachAdapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                }}>
                <Router>
                    <QuotationConfigurationsMenuComponent default/>
                    <EditorComponent path="/quotationconfiguration/*"/>
                    <QuotationConfigurationOverviewComponent path="/quotationconfiguration/overview"/>
                    <ConfigurationProductionOrderComponent breadcrumbComponent={<QuotationConfigurationsBreadcrumbs/>}
                                                           path="/quotationconfiguration/productionorder"/>
                    <ConfigurationEventsComponent breadcrumbComponent={<QuotationConfigurationsBreadcrumbs/>}
                                                  path="/quotationconfiguration/events"/>
                    <ConfigurationEventJsonComponent breadcrumbComponent={<QuotationConfigurationsBreadcrumbs/>}
                                                     path="/quotationconfiguration/eventdetails"/>
                </Router>
            </QueryParamProvider>
        </>
    );
};
