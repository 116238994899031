import {ConfigurationProductPreSelectionState} from "./productpreselection.model";
import {hasValue} from "../../../../../../services/validation.service";
import {IConfigurationProductPreSelectionModel} from "models/configurations/configuration_productpreselection";
import { ConfigurationMode } from "configurations/shared/configurationMode.model";
import { navigate } from "@reach/router";

export const validateStateForPostSKU = (state: ConfigurationProductPreSelectionState) => {
    return state.actualData.sku != null && state.actualData.sku.length === 10 && state.loadedData.configuration.sku != state.actualData.sku;
};


export const validateState = (state: ConfigurationProductPreSelectionState) => {
    if (state.actualData.isSelectedByFilter) {
        return validateStateWithFilter(state);
    } else {
        return validateStateWithoutFilter(state);
    }
};

export const validateStateWithoutFilter = (state: ConfigurationProductPreSelectionState) => {
    return state.loadedData?.configuration !== null && hasValue(state.loadedData.configuration.sku) && validateSideConfiguration(state) && validateFootStyleConfiguration(state);
};

export const validateStateWithFilter = (state: ConfigurationProductPreSelectionState) => {
    return validateSideConfiguration(state) && validateFootStyleConfiguration(state);
};


const validateSideConfiguration = (state: ConfigurationProductPreSelectionState) => {
    if (state.loadedData.preselectionConfigurationOptions && state.loadedData.preselectionConfigurationOptions.sides.length !== 0) {
        return hasValue(state.actualData.selectedSide);
    } else {
        return true;
    }
};

const validateFootStyleConfiguration = (state: ConfigurationProductPreSelectionState) => {
    if (state.loadedData.preselectionConfigurationOptions && state.loadedData.preselectionConfigurationOptions.footStyles.length !== 0) {
        return hasValue(state.actualData.selectedFootStyle);
    } else {
        return true;
    }
};

export const toCreateModel = (state: ConfigurationProductPreSelectionState): IConfigurationProductPreSelectionModel => {
    return {
        sku: state.actualData.sku,
        mtmFoot: state.actualData.selectedFootStyle,
        side: state.actualData.selectedSide
    };
};

export const toCreateModelForUpdateSKU = (state: ConfigurationProductPreSelectionState): IConfigurationProductPreSelectionModel => {
    return {
        sku: state.actualData.sku,
        mtmFoot: state.actualData.selectedFootStyle || null,
        side: state.actualData.selectedSide || null
    };
};

export const reloadConfiguration = (mode: ConfigurationMode, orderConfigurationId: string) : void =>
{
    let path = "";

    if (mode == ConfigurationMode.OrderConfiguration)
        path = `/orderconfigurations/orderconfiguration/create?orderconfigurationid=${orderConfigurationId}`;
    else
        path = `/quotationconfigurations/quotationconfiguration/create?orderconfigurationid=${orderConfigurationId}`;

    navigate(path);  
};