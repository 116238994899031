import {RouteComponentProps, Router} from "@reach/router";
import React, {useEffect} from "react";
import {EditorComponent} from "../shared/configuration_editor/editor.component";
import {OrderConfigurationsMenuComponent} from "./orderconfiguration_menu/orderconfigurationmenu.component";
import {QueryParamProvider} from "use-query-params";
import {ReachAdapter} from "use-query-params/adapters/reach";
import {parse, stringify} from "query-string";
import {
    OrderConfigurationOverviewComponent
} from "./orderconfiguration_overview/components/orderconfiguration_overview.component";
import ConfigurationProductionOrderComponent
    from "../shared/configuration_productionorder/components/configuration_productionorder.component";
import {AppDispatch} from "../../app/redux_store";
import {useAppDispatch} from "../../app/hook";
import {setBasePath, setMode} from "../shared/configurationmodeSlice";
import {ConfigurationMode} from "../shared/configurationMode.model";
import {getBasePath} from "../shared/basePathGetter";
import {OrderConfigurationsBreadcrumbs} from "./orderconfigurations.breadcrumbs";
import ConfigurationEventsComponent from "../shared/configuration_events/components/configuration_events.component";
import ConfigurationEventJsonComponent
    from "../shared/configuration_events/components/configuration_eventJson.component";

export const OrderConfigurationsComponent: React.FC<RouteComponentProps> = () => {
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setMode(ConfigurationMode.OrderConfiguration));
        dispatch(setBasePath(getBasePath()));
    }, []);
    return (
        <>
            <QueryParamProvider
                adapter={ReachAdapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                }}>
                <Router>
                    <OrderConfigurationsMenuComponent default/>
                    <EditorComponent path="/orderconfiguration/*"/>
                    <OrderConfigurationOverviewComponent path="/orderconfiguration/overview"/>
                    <ConfigurationProductionOrderComponent breadcrumbComponent={<OrderConfigurationsBreadcrumbs/>}
                                                           path="/orderconfiguration/productionorder"/>
                    <ConfigurationEventsComponent breadcrumbComponent={<OrderConfigurationsBreadcrumbs/>}
                                                  path="/orderconfiguration/events"/>
                    <ConfigurationEventJsonComponent breadcrumbComponent={<OrderConfigurationsBreadcrumbs/>}
                                                     path="/orderconfiguration/eventdetails"/>
                </Router>
            </QueryParamProvider>
        </>
    );
};
