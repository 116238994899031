import React, {useEffect} from "react";

import {useAppDispatch} from "app/hook";
import {AppDispatch} from "app/redux_store";
import {setExclusive} from "configurations/shared/configurationmodeSlice";

export interface ExclusiveModeComponentProps {
    paths: Array<string>
}

export const ExclusiveModeDetector: React.FC<ExclusiveModeComponentProps> = (props) => {

    const dispatch: AppDispatch = useAppDispatch();

    let match = false;

    // get path outside router
    props.paths.forEach(path => {
        const re = new RegExp(path, "g");
        const pathMatch = window.location.pathname.match(re);
        if (pathMatch) {
            match = true;
            return;
        }
    });

    useEffect(() => {
        if (match)
            dispatch(setExclusive());
    }, []);

    return (<></>);
};
