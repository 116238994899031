import {ApiResult} from "./api_result";
import {fetchWithTimeout} from "./fetch_with_timeout";
import {authenticationParameters, publicClientApplication} from "./../../services/aad.service";
import {getConfig} from "services/config.service";
import { v4 as uuidv4 } from "uuid";

class HttpService {
    timeoutMs: number;
    static session = uuidv4();

    constructor(timeoutMs?: number) {
        if (timeoutMs) {
            this.timeoutMs = timeoutMs;
        } else {
            const appConfig = getConfig();
            this.timeoutMs = Number(appConfig.httpTimeoutMs);
        }
    }

    public static recreateSessionId (): void
    {
        HttpService.session = uuidv4();
    }

    protected async get<T>(path: string): Promise<ApiResult<T>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            const result = new ApiResult<T>().withStatusCode(response.status);
            if (response.ok) {
                const data = await response.json();
                result.withData(data);
            }
            return result;
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<T>().withTimeout();
        }
    }

    protected async downloadFile(path: string): Promise<ApiResult<Blob>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "GET",
                headers: {
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });

            if (response.ok) {
                const fileData = await response.blob();
                return new ApiResult<Blob>()
                    .withStatusCode(response.status)
                    .withData(fileData);
            } else {
                return new ApiResult<Blob>().withStatusCode(response.status);
            }
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<Blob>().withTimeout();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async post<T>(path: string, content: T): Promise<ApiResult<any>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                body: JSON.stringify(content),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseBody = await response.json();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new ApiResult<any>().withStatusCode(response.status).withData(responseBody);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return new ApiResult<any>().withTimeout();
        }
    }


    protected async postForId<T>(path: string, content: T): Promise<ApiResult<number>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                body: JSON.stringify(content),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            const result = new ApiResult<number>().withStatusCode(response.status);
            if (response.ok) {
                const data = await response.text();
                result.withData(parseInt(data));
            }
            return result;
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<number>().withTimeout();
        }
    }

    protected async postForData<T>(path: string): Promise<ApiResult<T>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            const result = new ApiResult<T>().withStatusCode(response.status);
            if (response.ok) {
                const data = await response.json();
                result.withData(data);
            }
            return result;
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<T>().withTimeout();
        }
    }

    protected async postDataForData<TIn, TOut>(path: string, content: TIn): Promise<ApiResult<TOut>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                body: JSON.stringify(content),
                timeout: this.timeoutMs,
            });
            const result = new ApiResult<TOut>().withStatusCode(response.status);
            if (response.ok) {
                const data = await response.json();
                result.withData(data);
            }
            return result;
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<TOut>().withTimeout();
        }
    }

    protected async postForm(path: string, content: FormData): Promise<ApiResult<void>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                body: content,
                headers: {
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            return new ApiResult<void>().withStatusCode(response.status);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<void>().withTimeout();
        }
    }

    protected async uploadFileForm<T>(path: string, content: FormData): Promise<ApiResult<T>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "POST",
                body: content,
                headers: {
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            if (response.status === 422) {
                return new ApiResult<T>()
                    .withStatusCode(response.status)
                    .withData(await response.json());
            }
            return new ApiResult<T>().withStatusCode(response.status);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<T>().withTimeout();
        }
    }

    protected async editFileForm<T>(path: string, content: FormData): Promise<ApiResult<T>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "PUT",
                body: content,
                headers: {
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            if (response.status === 422) {
                return new ApiResult<T>()
                    .withStatusCode(response.status)
                    .withData(await response.json());
            }
            return new ApiResult<T>().withStatusCode(response.status);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<T>().withTimeout();
        }
    }

    protected async put<T>(path: string, content?: T): Promise<ApiResult<void>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "PUT",
                body: content ? JSON.stringify(content) : null,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            return new ApiResult<void>().withStatusCode(response.status);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<void>().withTimeout();
        }
    }

    protected async delete<T>(path: string, content?: T): Promise<ApiResult<void>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "DELETE",
                body: content ? JSON.stringify(content) : null,
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            return new ApiResult<void>().withStatusCode(response.status);
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<void>().withTimeout();
        }
    }

    protected async deleteWithResult<T>(path: string): Promise<ApiResult<T>> {
        try {
            const accessToken = await this.getAccessTokenHeader();
            const response = await fetchWithTimeout(path, {
                method: "DELETE",
                headers: {
                    Authorization: accessToken,
                    "OrsSessionId": HttpService.session,
                },
                timeout: this.timeoutMs,
            });
            return new ApiResult<T>()
                .withStatusCode(response.status)
                .withData(await response.json());
        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<T>().withTimeout();
        }
    }

    protected async postBeaconData(path: string, force?: boolean): Promise<ApiResult<boolean>> {
        try {
            const accessToken = await this.getAccessTokenViaMSAL();

            const blob = new Blob([JSON.stringify({"token": accessToken, "OrsSessionId": HttpService.session, "force": force})], {
                type: "application/vnd.medi.beacon+json" // send specific content-type to handle token at backend side
            });

            const response = navigator.sendBeacon(path, blob);

            const result = new ApiResult<boolean>().withStatusCode(200);
            result.withData(response);
            return result;

        } catch (error) {
            console.warn(`HTTP timeout after ${this.timeoutMs} ms`);
            return new ApiResult<boolean>().withTimeout();
        }
    }

    private async getAccessTokenViaMSAL(): Promise<string> {
        const accounts = publicClientApplication.getAllAccounts();
        if (accounts.length > 0) {
            const request = {
                scopes: authenticationParameters.scopes,
                account: accounts[0]
            };
            const accessToken = await publicClientApplication.acquireTokenSilent(request).then((response) => {
                return response.accessToken;
            }).catch(() => {
                // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
                return null;
            });

            return accessToken;
        }

        return null;
    }

    private async getAccessTokenHeader(): Promise<string> {
        const accessToken = await this.getAccessTokenViaMSAL();
        return `Bearer ${accessToken}`;
    }
}

export default HttpService;
