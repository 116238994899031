import React from "react";
import {BreadcrumbsComponent} from "../../shared/breadcrumbs/components/breadcrumbs";
import {BreadcrumbRoute} from "../../shared/breadcrumbs/models/breadcrumb_route";


export const QuotationConfigurationsBreadcrumbs: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderconfigurationid = urlParams.get("orderconfigurationid");

    const routes: BreadcrumbRoute[] = [
        {
            route: "/quotationconfigurations",
            parts: [{title: "Quotations"}]
        },
        {
            route: "/quotationconfigurations/quotationconfiguration/overview",
            parts:
                [
                    {title: "Quotations", url: "/quotationconfigurations"},
                    {title: "QuotationOverview"}
                ]
        },
        {
            route: "/quotationconfigurations/quotationconfiguration/productionorder",
            parts:
                [
                    {title: "Quotations", url: "/quotationconfigurations"},
                    {title: "QuotationOverview", url: "/quotationconfigurations/quotationconfiguration/overview"},
                    {title: "ProductionOrder"}
                ]
        },
        {
            route: "/quotationconfigurations/quotationconfiguration/events",
            parts:
                [
                    {title: "Quotations", url: "/quotationconfigurations"},
                    {title: "QuotationOverview", url: "/quotationconfigurations/quotationconfiguration/overview"},
                    {title: "QuotationEventOverview"}
                ]
        },
        {
            route: "/quotationconfigurations/quotationconfiguration/eventdetails",
            parts:
                [
                    {title: "Quotations", url: "/quotationconfigurations"},
                    {title: "QuotationOverview", url: "/quotationconfigurations/quotationconfiguration/overview"},
                    {
                        title: "QuotationEventOverview",
                        url: `/quotationconfigurations/quotationconfiguration/events?orderconfigurationid=${orderconfigurationid}`
                    },
                    {title: "Event"}
                ]
        }
    ];

    return <BreadcrumbsComponent<void> breadcrumbs={routes}/>;
};


