import React, {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "@reach/router";
import {AppDispatch} from "app/redux_store";
import {useAppDispatch, useAppSelector} from "app/hook";
import {
    changeStep,
    goBack,
    resetGotoAdditions,
    resetRestoreProductInstructions,
    resetSetProductInstructions,
    resetState,
    resetStepChange,
    setIsNavigate,
    updateCanEvaluate,
    updateOnProductionInstructionsEvaluated,
    updateOnProductionInstructionsLoaded,
} from "./redux/productinstructions.slice";
import {
    ConfigurationStepperComponent
} from "configurations/shared/configuration_editor/components/configuration_stepper.component";
import {
    ConfigurationNavigationComponent
} from "configurations/shared/configuration_editor/components/configuration_navigation.component";
import {getProductInstructions, gotoNext, setProductInstructions,} from "./redux/productinstructions.thunks";
import {getConfig} from "services/config.service";
import {canGotoNext} from "./redux/productinstructions.reducer";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {StepDescriptor} from "configurations/shared/configuration_editor/models/step_descriptor.model";
import {INavigationParams} from "configurations/shared/configuration_editor/models/navigation_params.model";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ProductionInstructionsWrapperComponent} from "./components/productinstructions_wrapper.component";
import {ContentContainer} from "shared/components/container/ContentContainer";
import {
    ProductInformation
} from "configurations/shared/configuration_editor/components/configuration_productinformation.component";
import IConfiguration from "../../../../../models/configurations/configuration";
import ErrorDialog from "../../../../../shared/components/error_dialog/error_dialog.component";
import {
    LoadingOverlayComponent,
    LoadingOverlayContainer,
} from "../../../../../shared/components/loading_overlay/loading_overlay.component";
import {updateAvailableSteps, updateStepperState} from "../../redux/stepper.slice";
import {ErrorDialogButtons} from "shared/components/error_dialog/error_dialog.helper";
import {useApiError} from "shared/provider/api_error/api_error.provider";
import {CommonApiResultError} from "shared/networking/common_api_result";
import {usePendingChanges} from "shared/provider/orderconfiguration/orderconfiguration.provider";
interface ProductInstructionsProps extends RouteComponentProps {
    configurationId: string;
    onNavigate: (
        next: StepDescriptor,
        params?: INavigationParams[]
    ) => void;
    getCustomerData: (orderConfiguration: IConfiguration) => void;
    configurationState: ConfigurationStepEnum;
}

export const ProductInstructionsComponent: React.FC<ProductInstructionsProps> = (props) => {
    const dispatch: AppDispatch = useAppDispatch();
    const instructionsRef = useRef(null);
    const state = useAppSelector((state) => state.configuration_productinstructions);
    const stepperState = useAppSelector((state) => state.configuration_stepper);
    const {apiError, setApiError} = useApiError();
    const {hasPendingChanges, setPendingChanges} = usePendingChanges();

    const [displayErrorDialog, setDisplayErrorDialog] = useState({
        isVisible: false,
        errorMessage: "",
    });

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    const [isLoading, setIsLoading] = useState(true);
    const [isMTMComponentLoading, setIsMTMComponentLoading] = useState(true);

    useEffect(() => {
        dispatch(updateStepperState(ConfigurationStepEnum.ProductionInstructionsSelection));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetState());
        }; // on exit component
    }, [dispatch]);

    useEffect(() => {
        if (!state.loadedData.isNavigating && props.configurationId) {
            dispatch(getProductInstructions(props.configurationId));
        }
    }, [props.configurationId]);

    useEffect(() => {
        if (state.loadedData?.configuration) {
            setIsLoading(false);
            props.getCustomerData(state.loadedData.configuration);
        } else {
            setPendingChanges(false);
            setIsLoading(true);
        }
    }, [state.loadedData]);

    useEffect(() => {
        if (state.loadedData.configuration !== null) {
            if (state.loadedData.configuration.productionType !== null && state.loadedData.configuration.productionType !== undefined) {
                dispatch(updateAvailableSteps(state.loadedData.configuration.productionType));
            }
        }
    }, [state.loadedData.configuration]);

    useEffect(() => {
        if (state.query.get.status === "error") {
            showError(state.query.get.message);
        } else {
            hideError();
        }
    }, [state.query.get.status]);

    useEffect(() => {
        if (state.command.goBackToAdditions.status === "success") {
            const target = stepperState.previousStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(resetGotoAdditions());
        }
    }, [state.command.goBackToAdditions.status]);

    useEffect(() => {
        if (state.command.setProductInstructions.status === "success") {
            const target = stepperState.nextStep;
            dispatch(setIsNavigate());
            props.onNavigate(target, [
                {
                    name: "productorderconfigurationid",
                    value: state.actualData.productConfigurationId,
                },
                {
                    name: "productorderconfigurationrunid",
                    value: state.actualData.productConfigurationRunId,
                },
            ]);
            dispatch(resetSetProductInstructions());
        }
        if (state.command.setProductInstructions.status === "error") {
            dispatch(resetSetProductInstructions());
            showError(state.command.setProductInstructions.message);
        }
    }, [state.command.setProductInstructions.status]);

    useEffect(() => {
        if (state.actualData.doSetProductionInstructions)
            dispatch(setProductInstructions());
    }, [state.actualData.doSetProductionInstructions]);

    useEffect(() => {
        if (state.actualData.handleStepChange === true) {
            const target = state.loadedData.stepState.targetStep;
            dispatch(setIsNavigate());
            props.onNavigate(target);
            dispatch(updateStepperState(target.value));
            dispatch(resetStepChange());
        }
    }, [state.actualData.handleStepChange]);

    return (
        <LoadingOverlayContainer>
            {displayErrorDialog.isVisible ? (
                <ErrorDialog
                    message={displayErrorDialog.errorMessage}
                    apiError={apiError}
                    buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                        dispatch(hideError);
                    })}
                />
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOverlayComponent/>
                    ) : (
                        <>
                            {
                                (state.loadedData.configuration?.sku !== null) &&
                                <ProductInformation
                                    sku={state.loadedData.configuration.sku}
                                    skuLongIdentifier={state.loadedData.configuration.productName}/>
                            }

                            <ConfigurationStepperComponent
                                onStepChangeExecute={(step: IStepChange) => dispatch(changeStep(step))}
                                orderConfigurationStateStep={props.configurationState}/>

                            <LoadingOverlayContainer>
                                {isMTMComponentLoading && <LoadingOverlayComponent></LoadingOverlayComponent>}

                                <ContentContainer>
                                    <ProductionInstructionsWrapperComponent
                                        ref={instructionsRef}
                                        mtmApiEndpointBase={getConfig().mtmService.baseUrl}
                                        orderConfiguration={state.loadedData.configuration}
                                        resetRestoreProductInstructions={() =>
                                            dispatch(resetRestoreProductInstructions())
                                        }
                                        fileNotValid={(details: string) => alert(details)}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        updateProductionInstructionsEvaluating={() => {
                                            setIsMTMComponentLoading(true);
                                        }}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        updateProductionInstructionsEvaluationFailed={(details) => {
                                            setApiError(details.detail as unknown as CommonApiResultError);
                                            showError("");
                                            setIsMTMComponentLoading(false);
                                        }}
                                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                                        updateProductionInstructionsEvaluated={(details) => {
                                            dispatch(updateOnProductionInstructionsEvaluated(details.detail));
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateProductionInstructionsLoading={() => {
                                            setIsMTMComponentLoading(true);
                                        }}
                                        updateProductionInstructionsLoadingFailed={(details) => {
                                            setApiError(details.detail as unknown as CommonApiResultError);
                                            showError("");
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateProductionInstructionsLoaded={(details) => {
                                            if (details.detail.componentData.isModified)
                                                setPendingChanges(true);

                                            dispatch(updateOnProductionInstructionsLoaded(details.detail));
                                            setIsMTMComponentLoading(false);
                                        }}
                                        updateGetComponentState={(details) => {
                                            if (details.detail.isModified)
                                                setPendingChanges(true);
                                            dispatch(updateCanEvaluate(details.detail));
                                        }}
                                    />
                                    <ConfigurationNavigationComponent
                                        canBack={state.loadedData.configuration != null}
                                        back={() => dispatch(goBack(stepperState.previousStep))}
                                        canNext={canGotoNext(state)}
                                        next={() => dispatch(gotoNext(instructionsRef))}
                                        hasPendingChanges={hasPendingChanges}
                                    />
                                </ContentContainer>
                            </LoadingOverlayContainer>
                        </>
                    )}
                </>
            )}
        </LoadingOverlayContainer>
    );
};
