import React from "react";
import {ErrorPanelComponent} from "./error_panel.component";
import {RouteComponentProps} from "@reach/router";

export const NotFoundComponent: React.FC<RouteComponentProps> = () => {
    return (
        <ErrorPanelComponent title="Nicht Verfügbar">
            Die angeforderte Seite steht nicht zur Verfügung.
        </ErrorPanelComponent>
    );
};
