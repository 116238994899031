import React from "react";
import {RouteComponentProps} from "@reach/router";
import {ThumbnailModel} from "../models/thumbnail.model";
import {ThumbnailComponent} from "../shared/components/buttons/thumbnail.component";
import orderConfigurationImage from "./images/orderconfigurations.jpg";
import quotationConfigurationImage from "./images/quotationconfigurations.jpg";
import {useTranslation} from "react-i18next";
import * as styleBreadcrumbs from "../shared/breadcrumbs/breadcrumbs_root.style";

import * as style from "./home.style";
import {Container} from "reactstrap";
import {HomeBreadcrumbs} from "./home.breadcrumbs";
import {ContentContainer} from "shared/components/container/ContentContainer";

export const HomeComponent: React.FC<RouteComponentProps> = () => {

    const {t} = useTranslation();

    const thumbnails: ThumbnailModel[] = [
        new ThumbnailModel(t("Orders"), orderConfigurationImage, "/orderconfigurations"),
        new ThumbnailModel(t("Quotations"), quotationConfigurationImage, "/quotationconfigurations")
    ];

    return (
        <>
            <styleBreadcrumbs.BreadcrumbArea>
                <Container>
                    <HomeBreadcrumbs/>
                </Container>
            </styleBreadcrumbs.BreadcrumbArea>
            <ContentContainer>
                <style.ThumbnailWrapper>
                    {thumbnails.map((thumb, i) => {
                        return (<ThumbnailComponent key={i} thumbnail={thumb}/>);
                    })}
                </style.ThumbnailWrapper>
            </ContentContainer>
        </>);
};
