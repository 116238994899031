import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import * as style from "../../../../../shared/style/form";
import { TextBoxComponent } from "../../../../../shared/components/form_controls/text_box/text_box.component";
import { setFootStyle, setIsReset, setMtmNumber, setSide, setSku } from "./redux/productpreselection.slice";
import { cloneMtmNumber, preselectProductPostSkU } from "./redux/productpreselection.thunks";
import { SecondaryButtonComponent } from "../../../../../shared/components/buttons/secondary_button_component";
import { ErrorText } from "../../../../../shared/components/error_text/error_text.style";
import { Collapsable } from "../../../../../shared/components/collapsable/Collapsable";
import { CollapsableSummary } from "../../../../../shared/components/collapsable/collapsable.summary";
import { CollapsableDetails } from "../../../../../shared/components/collapsable/collapsable.details";
import { RadioBoxComponent } from "../../../../../shared/components/form_controls/radio_box.component";
import Image_radio_buttons from "../../../../../shared/components/form_controls/image_radio_buttons";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../../../app/redux_store";
import { useAppDispatch, useAppSelector } from "../../../../../app/hook";
import { CollapsibleSummarySelection } from "../../../../../shared/components/collapsable/collapsibleSummarySelection.style";
import { RouteComponentProps } from "@reach/router";
import { useApiError } from "shared/provider/api_error/api_error.provider";
import ErrorDialog from "shared/components/error_dialog/error_dialog.component";
import { ErrorDialogButtons } from "shared/components/error_dialog/error_dialog.helper";

interface PreselectionSKUorMtmNumberProps extends RouteComponentProps {
    disabled: boolean
}

export const PreselectionSKUorMtmNumberComponent: React.FC<PreselectionSKUorMtmNumberProps> = (props) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useAppDispatch();
    const state = useAppSelector((state) => state.configuration_productpreselection);
    const [isSideSelectionExpanded, setIsSideSelectionExpanded] = useState(state.actualData.selectedSide === null);
    const [isFootStyleSelectionExpanded, setIsFootStyleSelectionExpanded] = useState(state.actualData.selectedFootStyle === null);

    const [displayErrorDialog, setDisplayErrorDialog] = useState({
        isVisible: false,
        errorMessage: "",
    });
    const { apiError, setApiError } = useApiError();

    const showError = (message: string) => {
        setDisplayErrorDialog({
            isVisible: true,
            errorMessage: message
        });
    };

    const hideError = () => {
        setDisplayErrorDialog({
            isVisible: false,
            errorMessage: "",
        });
    };

    useEffect(() => {
        dispatch(setIsReset(false));
    }, []);

    useEffect(() => {
        const { preselectionConfigurationOptions, configuration } = state?.loadedData || {};
        const { mtmSide } = configuration || {};

        const sides = preselectionConfigurationOptions?.sides || [];
        const filteredSides = sides.find(side => side.data === mtmSide);
        if (sides.length === 1) {
            dispatch(setSide(sides[0].data));

        } else {
            if (state.actualData.selectedSide !== null) {
                dispatch(setSide(state.actualData.selectedSide));
            } else {
                if (filteredSides) {
                    dispatch(setSide(filteredSides.data));
                } else {
                    const isSelectedSide = sides.find(side => side.isSelected);
                    if (isSelectedSide) {
                        dispatch(setSide(isSelectedSide.data));
                    } else {
                        dispatch(setSide(null));
                    }
                }
            }
        }
    }, [state?.loadedData?.preselectionConfigurationOptions?.sides]);


    useEffect(() => {
        const { preselectionConfigurationOptions, configuration } = state?.loadedData || {};
        const { mtmFoot } = configuration || {};

        const footStyles = preselectionConfigurationOptions?.footStyles || [];
        const filteredFootStyle = footStyles.find(footStyle => footStyle.data === mtmFoot);
        if (footStyles.length === 1) {
            dispatch(setFootStyle(footStyles[0].data));

        } else {
            if (state.actualData.selectedFootStyle !== null) {
                dispatch(setFootStyle(state.actualData.selectedFootStyle));
            } else {
                if (filteredFootStyle) {
                    dispatch(setFootStyle(filteredFootStyle.data));
                } else {
                    const isSelectedStyle = footStyles.find(footStyle => footStyle.isSelected);
                    if (isSelectedStyle) {
                        dispatch(setFootStyle(isSelectedStyle.data));
                    } else {
                        dispatch(setFootStyle(null));
                    }
                }
            }
        }
    }, [state?.loadedData?.preselectionConfigurationOptions?.footStyles]);

    useEffect(() => {
        setIsSideSelectionExpanded(state?.actualData?.selectedSide === null);
    }, [state?.actualData?.selectedSide]);

    useEffect(() => {
        setIsFootStyleSelectionExpanded(state?.actualData?.selectedFootStyle === null);
    }, [state?.actualData?.selectedFootStyle]);

    useEffect(() => {
        switch (state.command.cloneMtmNumber.status) {
            case "pending":
                break;

            case "error":
                showError(state.command.cloneMtmNumber.message);
                break;

            case "success":
                hideError();
                break;
        }
    }, [state.command.cloneMtmNumber.status]);

    if (displayErrorDialog.isVisible) {
        return (<ErrorDialog
            message={displayErrorDialog.errorMessage}
            apiError={apiError}
            buttons={ErrorDialogButtons.getButtonsByError(apiError, () => {
                dispatch(hideError);
                setApiError(null); 
            })}
        />);
    }

    return (
        <>
        <Grid container>
            <Grid
                item
                justifyContent="center"
                xs={6}>
                <style.FormRow>
                    <TextBoxComponent
                        name={t("COMMON:Sku")}
                        onChange={(e) => dispatch(setSku(e))}
                        value={state.actualData.sku}
                        maxLength={10}
                        isAutoFocus={!state.actualData.sku}
                        onEnterKeyDown={() => {
                            if (state.command.preselectPostSKU.canExecute) {
                                dispatch(preselectProductPostSkU());
                            }
                        }}
                        isReadonly={props.disabled}
                    />
                    <SecondaryButtonComponent
                        onClick={() => {
                            dispatch(preselectProductPostSkU());
                        }}
                        disabled={!state.command.preselectPostSKU.canExecute || props.disabled} key={"search"}>
                        {t("ProductPreselectionSkuSearchButton")}
                    </SecondaryButtonComponent>
                </style.FormRow>
            </Grid>
            {!state.loadedData.configuration.onceFinalized && <Grid
                item
                justifyContent="center"
                xs={6} >
                <style.FormRow>
                    <TextBoxComponent
                        name={t("COMMON:MtmNumber")}
                        onChange={(e) => {
                            dispatch(setMtmNumber(e));
                        }}
                        value={state.actualData.mtmNumber}
                        max={9999999999}
                        isNumeric={true}
                        onEnterKeyDown={() => {
                            if (state.command.cloneMtmNumber.canExecute)
                                dispatch(cloneMtmNumber({ mtmNumber: state.actualData.mtmNumber, targetId: state.loadedData.configuration.id }));
                        }}
                        isReadonly={props.disabled}
                    />
                    <SecondaryButtonComponent
                        onClick={() => {
                            if (state.command.cloneMtmNumber.canExecute)
                                dispatch(cloneMtmNumber({ mtmNumber: state.actualData.mtmNumber, targetId: state.loadedData.configuration.id }));
                        }}
                        disabled={!state.command.cloneMtmNumber.canExecute}>
                        {t("ProductPreselectionMtmNumberSearchButton")}
                    </SecondaryButtonComponent>
                </style.FormRow>
            </Grid>
            }
            {state.error.preselectSKUNotFoundError ?
                <ErrorText>{state.error.errormessage}</ErrorText> :
                <>
                    {state.loadedData.preselectionConfigurationOptions !== null && state.actualData.sku === state.loadedData?.configuration?.sku &&
                        <>
                            <Grid
                                item
                                justifyContent="center"
                                xs={12}
                            >
                                {state.loadedData.preselectionConfigurationOptions.sides.length > 0 &&
                                    <Collapsable
                                        handleChange={(event, expanded) => setIsSideSelectionExpanded(expanded)}
                                        expanded={isSideSelectionExpanded}
                                        disabled={props.disabled}>
                                        <CollapsableSummary>
                                            {t("COMMON:Side")}
                                            <CollapsibleSummarySelection>
                                                {state.actualData.selectedSide !== null ? state.loadedData.preselectionConfigurationOptions.sides.find(side => side.data === state.actualData.selectedSide)?.displayName : ""}
                                            </CollapsibleSummarySelection>
                                        </CollapsableSummary>
                                        <CollapsableDetails>
                                            <RadioBoxComponent
                                                name={""}
                                                data={state.loadedData.preselectionConfigurationOptions.sides}
                                                displayedProperties={["displayName"]}
                                                onSelect={(e) => dispatch(setSide(e))}
                                                valueProperty={"data"}
                                                selectedItemData={state.actualData.selectedSide}
                                            ></RadioBoxComponent>
                                        </CollapsableDetails>
                                    </Collapsable>
                                }
                                {state.loadedData.preselectionConfigurationOptions.footStyles.length > 0 &&
                                    <Collapsable
                                        handleChange={(event, expanded) => setIsFootStyleSelectionExpanded(expanded)}
                                        expanded={isFootStyleSelectionExpanded}
                                        disabled={props.disabled}>
                                        <CollapsableSummary>
                                            {t("COMMON:FootStyle")}
                                            <CollapsibleSummarySelection>
                                                {state.actualData.selectedFootStyle !== null ? state.loadedData.preselectionConfigurationOptions.footStyles.find(footStyle => footStyle.data === state.actualData.selectedFootStyle)?.displayName : ""}
                                            </CollapsibleSummarySelection>
                                        </CollapsableSummary>
                                        <CollapsableDetails>
                                            <Image_radio_buttons
                                                name={""}
                                                data={state.loadedData.preselectionConfigurationOptions.footStyles}
                                                displayedProperties={["displayName"]}
                                                onSelect={(e) => dispatch(setFootStyle(e))}
                                                valueProperty={"data"}
                                                selectedItem={state.actualData.selectedFootStyle}
                                            ></Image_radio_buttons>
                                        </CollapsableDetails>
                                    </Collapsable>
                                }
                            </Grid></>
                    }
                </>}
                </Grid></>
    );
};
