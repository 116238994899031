import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApi} from "./productlineselection.reducer";

export const selectProductLine = createAsyncThunk(
    "configuration/productlineselection",
    async () => {
        // const state = getState() as RootState;
        // const response = await ConfigurationService.selectProductLine(state.orderConfiguration_productlineselection.loadedData.stepState.configurationId, toCreateModel(state.orderConfiguration_productlineselection));
        // handleResponseErrors(response, "OrderConfiguration");
        // return response;
    }
);

export const getOrderConfiguration = createAsyncThunk(
    "configuration/productlineselection/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getSelectProductLine(id, mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const skipSelectProductline = createAsyncThunk(
    "configuration/productlineselection/skipGet",
    // eslint-disable-next-line
    async () => {
    }
);

export const goBack = createAsyncThunk(
    "configuration/productlineselection/gotomeasurements",
    // eslint-disable-next-line
    async () => {
    }
);

export const gotoNext = createAsyncThunk(
    "configuration/productlineselection/gotonext",
    async (_, {getState, dispatch}) => {
        const state = getState() as RootState;

        // if(state.configuration_editor.loadedData.orderConfigurationState.readonly) {
        //     dispatch(navigateTo({
        //         targetStep: determineNextStep(ConfigurationStepEnum.ProductLineSelection, state.configuration_productlineselection.loadedData.configuration.productionType),
        //         configurationId: state.configuration_editor.loadedData.configurationId,
        //         productConfigurationId: state.configuration_editor.loadedData.productConfigurationId,
        //         productConfigurationRunId: state.configuration_editor.loadedData.productConfigurationRunId
        //     }));
        //     return;
        // }

        if (mustCallApi(state.configuration_productlineselection)) {
            await dispatch(selectProductLine());
        } else {
            await dispatch(skipSelectProductline());
        }
    }
);
