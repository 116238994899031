import {createSlice} from "@reduxjs/toolkit";
import {OrderConfigurationProductSelectionState} from "./productselection.model";
import {getOrderConfiguration, goBack, gotoNext, selectProduct, skipSelectProduct} from "./productselection.thunks";
import {setErrorInState} from "shared/networking/error_handling";
import { PassOrderConfigurationProvider } from "shared/provider/orderconfiguration/orderconfiguration.provider";

const initialState: OrderConfigurationProductSelectionState = {
    loadedData: {
        // stepState: {
        //     currentStep: OrderConfigurationStepEnum.ProductSelection,
        //     configurationId: null,
        // },
        orderConfiguration: null,
        isNavigating: false,
    },
    actualData: {
        handleStepChange: false,
    },
    command: {
        selectProduct: {
            status: "idle",
            canExecute: true
        },
        goBackToProductlineSelect: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationProductSelectionSlice = createSlice({
    name: "configuration/productselection",
    initialState,
    reducers: {
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            PassOrderConfigurationProvider.setPendingChanges(false);
        },
        resetSelectProduct: (state) => {
            state.command.selectProduct.status = "idle";
            // state.loadedData.stepState.targetStep = null;
        },
        resetGotoProductlineSelection: (state) => {
            state.command.goBackToProductlineSelect.status = "idle";
            // state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            // state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        changeStep: (state) => {
            // state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
    }, extraReducers: (builder) => {
        // gotoMeasurements
        builder.addCase(goBack.pending, (state) => {
            state.command.goBackToProductlineSelect.status = "pending";
        }).addCase(goBack.fulfilled, (state) => {
            state.command.goBackToProductlineSelect.status = "success";
            // const targetStep = ConfigurationStepValues[state.loadedData.stepState.currentStep - 1];
            // state.loadedData.stepState.targetStep = {...targetStep, url: getBasePath() + "/" + targetStep.url};
        })

            // getOrderConfiguration
            .addCase(getOrderConfiguration.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            }).addCase(getOrderConfiguration.rejected, (state, action) => {
            setErrorInState(state.query.get, action);
        }).addCase(getOrderConfiguration.fulfilled, (state, action) => {
            state.query.get.status = "success";
            state.loadedData.orderConfiguration = action.payload.getData();
            // state.loadedData.stepState.configurationId = action.payload.getData().id;
        })

            // selectProduct
            .addCase(selectProduct.rejected, (state, action) => {
                setErrorInState(state.command.selectProduct, action);
            }).addCase(selectProduct.fulfilled, (state) => {
            state.command.selectProduct.status = "success";
            // const targetStep = ConfigurationStepValues[state.loadedData.stepState.currentStep + 1];
            // state.loadedData.stepState.targetStep = {...targetStep, url: getBasePath() + "/" + targetStep.url};
        })

            // skipSelectProduct
            .addCase(skipSelectProduct.fulfilled, (state) => {
                state.command.selectProduct.status = "success";
                // const targetStep = ConfigurationStepValues[state.loadedData.stepState.currentStep + 1];
                // state.loadedData.stepState.targetStep = {...targetStep, url: getBasePath() + "/" + targetStep.url};
            })

            // gotoNext
            .addCase(gotoNext.pending, (state) => {
                state.command.selectProduct.status = "pending";
                state.command.selectProduct.canExecute = false;
            });
    }
});

export const {
    resetState,
    resetSelectProduct,
    resetGotoProductlineSelection,
    resetStepChange,
    setIsNavigate,
    changeStep,
} = configurationProductSelectionSlice.actions;

export default configurationProductSelectionSlice.reducer;
